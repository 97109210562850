var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"blocks",class:{ 'sub-block': _vm.subBlock }},[(_vm.subBlock)?_c('div',{staticClass:"settings-block"},[_c('div',{staticClass:"delete-block",on:{"click":function($event){return _vm.$emit('deleteDataBlock')}}},[_c('img',{attrs:{"src":"/static/icons/close.svg","alt":""}})]),_c('h2',[_vm._v("Вложенный блок")]),_c('custom-checkbox',{attrs:{"label":"Множественный тип"},on:{"get":function($event){return _vm.setData('kind', $event ? 'Array' : 'Single')}}})],1):_vm._e(),_c('input-container',{attrs:{"value":_vm.blockStructure.label,"options":{
      type: 'Text',
      label: 'Название блока',
      required: true,
    }},on:{"get":_vm.setLabel}}),_vm._l((_vm.blockStructure.fields),function(b,k){return [(b.type === 'SubBlock')?_c('create-block-component',{key:k,attrs:{"blockTypes":_vm.blockTypes,"blockStructure":b,"subBlock":true},on:{"deleteDataBlock":function($event){return _vm.deleteDataBlock(k)},"setData":function($event){return _vm.setSubBlockData(k, $event)}}}):_c('create-form-component',{key:k,attrs:{"formData":b},on:{"setData":function($event){return _vm.setSubBlockData(k, $event)},"deleteDataBlock":function($event){return _vm.deleteDataBlock(k)}}})]}),_c('custom-selector',{key:_vm.blockStructure.fields.length,attrs:{"options":{
      label: 'Добавить поле',
      inValue: false,
      data: _vm.blockTypes,
      size: 2,
    }},on:{"get":_vm.addForm}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }