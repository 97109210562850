import { render, staticRenderFns } from "./ControlPage.vue?vue&type=template&id=026e1230&scoped=true&"
import script from "./ControlPage.vue?vue&type=script&lang=js&"
export * from "./ControlPage.vue?vue&type=script&lang=js&"
import style0 from "./ControlPage.vue?vue&type=style&index=0&id=026e1230&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "026e1230",
  null
  
)

export default component.exports