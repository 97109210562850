<template>
  <article :class="{ 'sub-block': subBlock, outside }">
    <h2>
      {{ form.label }}
      <order-block
        v-if="(form.kind === 'Array' || !subBlock) && !outside"
        :order="block.sortOrder"
        @changeOrder="sortByOrder"
      />
    </h2>
    <div class="mass-container">
      <template v-for="(d, k) in form.fields">
        <custom-checkbox
          v-if="d.type === 'Checkbox'"
          :key="d.type + k"
          :ref="d.name"
          :label="d.label"
          :value="block.data[d.name]"
          @get="emitConstructObject(d.name, $event)"
        />
        <custom-selector
          v-else-if="d.type === 'Selector'"
          :key="d.type + k"
          :ref="d.name"
          :label="d.label"
          :data="d.params.values"
          :kind="d.kind"
          :language="language"
          :selectedValue="block.data[d.name]"
          @get="emitConstructObject(d.name, $event)"
        />
        <photo-loader
          v-else-if="d.type === 'Image' || d.type === 'File'"
          :key="d.type + k"
          :ref="d.name"
          :options="{
            label: d.label,
            minContent: '',
            validation: d.validation,
            onlyOne: d.kind !== 'Array',
            type: d.type,
          }"
          :images="block.data[d.name]"
          @photoId="setImgToObject(d.name, $event)"
        />
        <multiplie-block
          v-else-if="d.type === 'SubBlock'"
          :key="d.type + k"
          :ref="d.name"
          :form="d"
          :language="language"
          :block="block"
          @setBlocks="emitConstructObject(d.name, $event)"
          @addBlock="addBlock(d, d.name)"
          @deleteBlock="deleteBlock($event, d.name)"
        />
        <tag-container
          v-else-if="d.kind === 'Array' && d.type === 'Text'"
          :key="d.type + k"
          :ref="d.name"
          :value="block.data[d.name]"
          :language="language"
          :options="{
            label: d.label,
            validation: d.validation,
            localisation: d.languages.length[0],
          }"
          @get="emitConstructObject(d.name, $event)"
        />
        <input-container
          v-else
          :key="d.type + k"
          :ref="d.name"
          :value="
            d.languages.length > 0
              ? whichVal('data', null, d.name)
              : block.data[d.name]
          "
          :options="{
            type: d.type,
            label: d.label,
            validation: d.validation,
            kind: d.kind,
          }"
          @get="onChange(d.name, $event, d.languages.length > 0)"
        />
      </template>
    </div>
    <div v-if="checkButtons().all" class="control-btns">
      <button
        v-if="checkButtons().addBtn"
        @click="$emit('addBlock')"
        class="add"
      >
        Добавить
      </button>
      <button
        v-if="checkButtons().deleteBtn"
        @click="$emit('deleteBlock')"
        class="delete"
      >
        Удалить
      </button>
    </div>
  </article>
</template>

<script>
import InputContainer from "./InputContainer.vue";
import inputHandlerMixin from "@/mixins/inputHandlerMixin";
import PhotoLoader from "./PhotoLoader.vue";
import OrderBlock from "./OrderBlock.vue";
import CustomCheckbox from "./CustomCheckbox.vue";
import CustomSelector from "./CustomSelector.vue";
import MultiplieBlock from "./MultiplieBlock.vue";
import TagContainer from "./TagContainer.vue";
export default {
  name: "ContentBlock",
  mixins: [inputHandlerMixin],
  components: {
    InputContainer,
    PhotoLoader,
    OrderBlock,
    CustomCheckbox,
    CustomSelector,
    MultiplieBlock,
    TagContainer,
  },
  props: {
    language: {
      default: "ru-RU",
    },
    form: {
      default: () => ({}),
    },
    block: {
      default: () => ({}),
    },
    last: {
      default: false,
    },
    subBlock: {
      default: false,
    },
    outside: {
      default: false,
    },
  },
  data() {
    return {
      objName: "block",
      show: false,
    };
  },
  methods: {
    sortByOrder(newOrder) {
      this.$emit("sort", this.block.sortOrder, newOrder);
    },
    checkReq(errComponent) {
      let chk = false;
      for (let k in this.$refs) {
        this.$refs[k].forEach((e) => {
          chk = e.checkReq(errComponent);
          if (chk) return;
        });
      }
      return chk;
    },
    setImgToObject(name, ids) {
      this.emitConstructObject(name, ids);
    },
    checkButtons() {
      const addBtn = this.form.kind === "Array" && this.last;
      const deleteBtn =
        (!this.subBlock || (this.subBlock && this.form.kind === "Array")) &&
        !this.outside;
      return {
        addBtn,
        deleteBtn,
        all: addBtn || deleteBtn,
      };
    },
    addBlock(form, name) {
      const obj = this.add(form.fields);
      this.emitConstructObject(name, [...this.block.data[name], obj]);
    },
    deleteBlock(k, name) {
      const filteredArray = [...this.block.data[name]];
      this.block.data[name].length > 1 ? filteredArray.splice(k, 1) : null;
      this.emitConstructObject(name, filteredArray);
    },
    emitConstructObject(name, filteredArray) {
      const newData = {
        ...this.block.data,
        [name]:
          typeof filteredArray !== "object" ||
          filteredArray.length === undefined
            ? filteredArray
            : [...filteredArray],
      };
      const newInfo = {
        ...this.block,
        data: newData,
      };
      if (this.subBlock) {
        delete newData.sortOrder;
      }
      this.$emit("setData", this.subBlock ? newData : newInfo);
    },
    setSubBlockInfo(event, name) {
      const filteredArray = [...this.block.data[name]];
      filteredArray.splice(event.key, 1, event.data);
      this.emitConstructObject(name, filteredArray);
    },
    onChange(name, value, translate = false) {
      if (!translate) {
        this.emitConstructObject(name, value);
      } else {
        const filteredArray = this.block.data[name].filter(
          (e) => e.lang !== this.language
        );
        this.emitConstructObject(name, [
          ...filteredArray,
          {
            lang: this.language,
            value,
          },
        ]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/styles/config.scss";

article {
  display: grid;
  gap: ptr(20px) 0;
  max-width: ptr(700px);
  padding-top: ptr(30px);
  position: relative;
  &::before {
    content: "";
    width: ptr(1200px);
    height: ptr(1px);
    position: absolute;
    background: rgb(129, 129, 129);
    top: ptr(0px);
  }
}

h2 {
  display: flex;
  font-size: ptr(30px);
  font-weight: 500;
  width: 100%;
  margin: 0;
  color: $h-color;
  position: sticky;
  top: 0;
  background: $back-color;
  z-index: 20;
  padding: ptr(20px) 0;
}

.outside {
  border: none;
}

.sub-block {
  border-top: 1px solid $border-color;
  &::before {
    content: none;
  }
  h2 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    position: relative;
    z-index: 1;
    padding: 0;
    font-size: ptr(22px);
  }
  .order-conteiner {
    margin: 0 ptr(20px) 0 0;
  }
  &:nth-child(1) {
    padding-top: ptr(0px);
    border-top: unset;
  }
}

.mass-container {
  display: flex;
  flex-wrap: wrap;
  gap: ptr(30px) ptr(20px);
}

.control-btns {
  display: flex;
  align-items: center;
}
</style>
