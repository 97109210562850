<template>
  <main id="app">
    <requests-line />
    <div class="side-menu">
      <img :src="project.img" alt="" class="logo" />
      <div class="logo-inf">
        <h1>
          {{ project.name }}
        </h1>
        <span>admin panel</span>
      </div>
      <nav>
        <ul>
          <li v-if="isAdmin">
            <router-link to="/control-panel">Панель управления</router-link>
          </li>
          <li>
            <router-link to="/cases">Кейсы</router-link>
          </li>
          <li v-for="p in pages" :key="p.id">
            <router-link :to="`/page/${p.id}`">{{ p.title }}</router-link>
          </li>
        </ul>
      </nav>
      <button @click="logout" class="exit">Выйти</button>
    </div>
    <router-view :project="project" :key="$route.path" />
  </main>
</template>

<script>
import { authService } from "@/components/AuthService/authService";
import RequestsLine from "@/components/RequestsLine";
import { http } from "./components/AuthService/httpService";
export default {
  components: { RequestsLine },
  data() {
    return {
      project: {
        name: "MAGNITOFON",
        img: "./logo.svg",
      },
      pages: [],
      data: [],
      isAdmin: false,
    };
  },
  methods: {
    logout() {
      authService.logout().then(() => {
        this.$router.replace("/Auth");
      });
    },
    getPages() {
      http.get("/api/pages").then((response) => {
        if (response.status < 400) {
          this.pages = response.data.pages;
        }
      });
    },
  },
  mounted() {
    setTimeout(() => {
      if (authService.isAuth) {
        this.getPages();
      }
    }, 100);
    this.isAdmin = authService.parsedAccessToken?.Role === "Admin" || false;
    document.addEventListener("login", () => {
      this.isAdmin = authService.parsedAccessToken?.Role === "Admin" || false;
      this.getPages();
    });
  },
};
</script>

<style lang="scss">
@import "./components/styles/config.scss";
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: ptr(50px) ptr(55px) ptr(100px) ptr(350px);
}
html {
  background: $back-color;
}
body {
  margin: 0;
  font-family: $main-font;
  background: $back-color;
}
ul {
  padding: 0;
  margin: 0;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: $text-color;
}
button {
  font-family: $main-font;
  cursor: pointer;
  border: none;
}
.star-info {
  grid-column: span 2;
  width: 100%;
  font-size: ptr(16px);
  font-weight: 500;
  color: #969fbb;
  span {
    color: $red;
  }
}
.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $active-color;
  border-radius: $brd;
  color: white;
  font-weight: 500;
  font-size: ptr(14px);
  min-width: ptr(145px);
  height: ptr(46px);
  padding: 0 ptr(40px);
  transition: $trs;
  &:hover {
    background: #008ad8;
  }
  &:active {
    background: #007abe;
  }
}
.add {
  width: ptr(203px);
  height: ptr(46px);
  border-radius: $brd;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #68c9ff;
  color: white;
  font-weight: 500;
  font-size: ptr(14px);
  transition: $trs;
  &:hover {
    background: #3fbcff;
  }
}
.delete {
  height: ptr(46px);
  width: ptr(146px);
  color: white;
  font-weight: 500;
  border-radius: ptr(10px);
  background: $red;
  font-size: ptr(14px);
}

.label-info {
  color: $text-color;
  font-size: ptr(16px);
  font-weight: 500;
  width: fit-content;
  position: relative;
  margin-bottom: ptr(10px);
}

.req {
  margin-left: ptr(5px);
  display: inline-block;
  color: $red;
}

.error-text {
  position: absolute;
  right: ptr(-210px);
  top: 0;
  width: ptr(230px);
  background: white;
  border: 1px solid $red;
  border-radius: ptr(5px);
  padding: ptr(10px);
  z-index: 10;
  pointer-events: none;
}

.btns {
  display: flex;
  align-items: center;
  gap: ptr(30px);
  margin-top: ptr(60px);
}
.loading {
  background: rgb(157, 174, 184);
  color: white;
}
</style>

<style lang="scss" scoped>
@import "./components/styles/config.scss";

.side-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: ptr(296px);
  box-sizing: border-box;
  background: white;
  padding: ptr(50px) ptr(30px);
}
.logo {
  width: ptr(86px);
  margin-left: ptr(20px);
}
.logo-inf {
  margin-left: ptr(20px);
  h1 {
    margin: ptr(20px) 0 ptr(5px);
    font-size: ptr(28px);
    font-weight: 700;
    color: $h-color;
  }
  span {
    font-size: ptr(22px);
    font-weight: 500;
    color: $h-color;
  }
}
nav {
  margin-top: ptr(120px);
}
ul {
  display: grid;
  gap: ptr(5px);
}
a {
  display: flex;
  align-items: center;
  padding-left: ptr(20px);
  font-size: ptr(18px);
  font-weight: 500;
  background: rgba(255, 255, 255, 0);
  transition: $trs;
  height: ptr(41px);
  border-radius: $brd;
}
.router-link-exact-active {
  color: $active-color;
  background: #f4fbff;
}
.exit {
  background: $active-color;
  width: ptr(196px);
  height: ptr(42px);
  position: absolute;
  bottom: ptr(50px);
  left: ptr(50px);
  border-radius: $brd;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: ptr(14px);
  font-weight: 500;
}
</style>
