<template>
	<div :class="{ separate: !withLabel }" class="block-selector">
		<div class="label-info">Выбрать блоки</div>
		<div class="select-block" @click="openCards = true">
			<span>
				Выбрать
			</span>
		</div>
		<div
			v-if="openCards"
			class="select-block-container"
			@click.self="openCards = false"
		>
			<div class="options-container">
				<h2>
					Выбрать блок
				</h2>
				<div class="blocks-container">
					<div
						v-for="(e, k) in data"
						:key="k"
						@click="$emit('add', e)"
						class="opt"
					>
						<p class="name">
							<span>
								{{ e.label }}
							</span>
							<span class="elements">
								Элементов: {{ e.fields.length }}
							</span>
						</p>
						<p class="includes">
							Содержимое :
						</p>
						<ul>
							<li v-for="(f, kf) in e.fields" :key="kf">
								- {{ f.label }}
							</li>
						</ul>
						<!-- <div class="check-block">
							<img src="/static/icons/eye.svg" alt="" />
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		withLabel: {
			default: true,
		},
		data: {
			default: () => [],
		},
	},
	data() {
		return {
			openCards: false,
		};
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";
.block-selector {
	display: grid;
	gap: ptr(10px);
	width: 100%;
	max-width: ptr(500px);
	position: relative;
}
.options-container {
	position: absolute;
	z-index: 2;
	width: ptr(1000px);
	height: ptr(800px);
	border-radius: ptr(20px);
	background: white;
	border: 1px solid $border-color;
	box-sizing: border-box;
	padding: ptr(20px) ptr(30px);
}

.select-block {
	width: ptr(200px);
	min-height: ptr(100px);
	height: 100%;
	border-radius: ptr(8px);
	border: 1px dashed $border-color;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	span {
		font-size: ptr(16px);
		font-weight: 500;
	}
}

.select-block-container {
	width: 100%;
	height: 100vh;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
	background: rgba(0, 0, 0, 0.3);
	display: flex;
	align-items: center;
	justify-content: center;
}

.blocks-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: ptr(10px);
}

.opt {
	padding: ptr(20px) ptr(30px) ptr(20px) ptr(20px);
	font-weight: 500;
	border-radius: ptr(6px);
	box-shadow: 0 0 ptr(20px) ptr(-5px) rgba(0, 0, 0, 0.1);
	font-size: ptr(16px);
	border: 1px solid #eaf0fc;
	cursor: pointer;
	display: grid;
	&:hover {
		background: $active-color;
		color: white;
		.elements {
			color: white;
		}
	}
}

.name {
	margin: 0;
	display: grid;
	gap: ptr(3px);
	font-size: ptr(18px);
}

.includes {
	margin: ptr(20px) 0 0;
	font-weight: 500;
	font-size: ptr(16px);
}

li {
	font-weight: 400;
	font-size: ptr(14px);
}

.separate {
	.label-info {
		display: none;
	}
}

.check-block {
	border-radius: $brd;
	background: white;
	width: ptr(30px);
	height: ptr(30px);
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	img {
		width: ptr(20px);
		height: ptr(20px);
		object-fit: contain;
	}
}

.elements {
	font-size: ptr(14px);
	transition: 0;
	color: $text-color;
}
</style>
