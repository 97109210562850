var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photo-loader"},[_c('div',{staticClass:"label-info"},[_vm._v(" "+_vm._s(_vm.options.label)+" "),(_vm.options.validation.required)?_c('div',{staticClass:"req"},[_vm._v(" * ")]):_vm._e(),(_vm.error.catch)?_c('div',{staticClass:"error-text"},[_vm._v(" "+_vm._s(_vm.error.text)+" ")]):_vm._e(),(_vm.options.minContent)?_c('span',{staticClass:"min-size"},[_vm._v("Мин размер "+_vm._s(_vm.options.minContent)+"px")]):_vm._e(),(
				_vm.options.validation.maxCount ||
					_vm.options.validation.minCount ||
					_vm.options.validation.exactCount
			)?_c('span',{staticClass:"min-size"},[_vm._v(_vm._s(_vm.photos.length)+" / "+_vm._s(_vm.options.validation.maxCount || _vm.options.validation.minCount || _vm.options.validation.exactCount))]):_vm._e()]),_c('div',{staticClass:"photos-container"},[_c('label',[(_vm.options.onlyOne)?_c('input',{attrs:{"type":"file"},on:{"change":_vm.addPhotos}}):_c('input',{attrs:{"multiple":"","type":"file"},on:{"change":_vm.addPhotos}}),_c('div',{staticClass:"custom-file-get",class:{ requared: _vm.error.catch }},[_vm._v(" Выбрать файлы ")])]),_vm._l((_vm.photos),function(p,k){return _c('div',{key:k,staticClass:"photo",class:{
				loaded: p.loaded,
				drag: _vm.photoDrag === k,
				over: _vm.photoOver === k,
			},attrs:{"draggable":"true"},on:{"dragover":function($event){$event.preventDefault();},"drop":function($event){return _vm.drop(p.sort)},"dragleave":function($event){return _vm.dragLeave()},"dragend":_vm.dragEnd,"dragenter":function($event){_vm.photoOver = p.sort},"dragstart":function($event){_vm.photoDrag = p.sort}}},[_c('img',{staticClass:"close",attrs:{"src":"/static/icons/close.svg","alt":""},on:{"click":function($event){return _vm.slice(k)}}}),(p.type === 'video')?_c('video',{staticClass:"content",attrs:{"src":p.url,"muted":"","autoplay":"","loop":""},domProps:{"muted":true}}):(p.type === 'image')?_c('img',{staticClass:"content",attrs:{"src":p.url,"alt":""}}):_c('span',[_vm._v(" "+_vm._s(p.url)+" ")])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }