<template>
  <section>
    <div class="top-block">
      <h1>Кейс</h1>
      <lang-selector @get="(e) => (language = e)"></lang-selector>
    </div>
    <div class="static-form">
      <input-container
        ref="name"
        :value="whichVal('name')"
        :options="{
          label: 'Название',
        }"
        @get="onChange('name', $event, true)"
      ></input-container>
      <input-container
        ref="url"
        :value="data.url"
        :options="{
          label: 'Ссылка',
        }"
        @get="onChange('url', $event)"
      ></input-container>
      <custom-selector
        :options="{
          label: 'Группа',
          data: groups,
          required: true,
          kind: 'Array',
        }"
        :selectedValue="data.groups"
        @get="data.groups = $event"
      >
      </custom-selector>
      <input-container
        ref="description"
        :value="whichVal('description')"
        :options="{
          label: 'Описание',
          type: 'Textarea',
        }"
        @get="onChange('description', $event, true)"
      ></input-container>
    </div>
    <div class="blocks">
      <ContentBlock
        ref="blocks"
        v-for="(f, k) in form"
        :key="f.id + k"
        :form="f"
        :block="data.blocks[getCurrentScheme(f.id)]"
        :language="language"
        :outside="true"
        @setData="setBlocks($event, k, f.id)"
      />
    </div>
    <button @click="saveAbout" class="add">
      {{ id ? "Обновить данные" : "Создать кейс" }}
    </button>
  </section>
</template>

<script>
import { http } from "../components/AuthService/httpService";
import inputHandlerMixin from "@/mixins/inputHandlerMixin";
import LangSelector from "./components/LangSelector.vue";
import ContentBlock from "@/components/Blocks/ContentBlock.vue";
import InputContainer from "../components/Blocks/InputContainer.vue";
import CustomSelector from "../components/Blocks/CustomSelector.vue";
export default {
  components: { LangSelector, ContentBlock, InputContainer, CustomSelector },
  mixins: [inputHandlerMixin],
  data() {
    return {
      language: "ru-RU",
      id: null,
      form: [],
      groups: [],
      objName: "data",
      data: {
        name: [
          {
            lang: "ru-RU",
            value: "",
          },
        ],
        description: [
          {
            lang: "ru-RU",
            value: "",
          },
        ],
        url: "",
        groups: [],
        isPublished: true,
        blocks: [
          {
            data: {
              Izobrazheniya: [],
            },
            id: "bb1a7bfa-0899-42d4-9229-610b122e8260",
            sortOrder: 0,
          },
        ],
      },
    };
  },
  methods: {
    collectBlocks() {
      http.get("/api/blocks").then((response) => {
        if (response.status < 400) {
          for (let b of response.data.blocks) {
            if (this.data.blocks.findIndex((e) => e.id === b.id) > -1) {
              this.form.push(b);
            }
          }
        }
      });
    },
    getCurrentScheme(id) {
      return this.data.blocks.findIndex((e) => e.id === id);
    },
    getGroups() {
      http.get("/api/case-groups").then((response) => {
        if (response.status < 400) {
          this.groups = response.data.groups;
        }
      });
    },
    setBlocks(data, k, id) {
      this.data.blocks.splice(k, 1, { ...data, id });
    },
    getPageInfo(id) {
      http.get(`/api/cases/${id}`).then(({ data }) => {
        this.data = {
          ...data,
          groups: this.groups.filter(
            (e) => data.groups.findIndex((g) => g === e.id) > -1
          ),
        };
      });
    },
    saveAbout() {
      this.loading = true;
      const recursiveImageId = (fields, data) => {
        const newData = { ...data };
        for (let f of fields) {
          if (
            (f.type === "Image" || f.type === "File") &&
            newData[f.name] !== null
          ) {
            newData[f.name] =
              f.kind === "Array"
                ? newData[f.name].map((i) => i.id)
                : newData[f.name].id;
          } else if (f.type === "SubBlock") {
            if (f.kind === "Array") {
              newData[f.name] = newData[f.name].map((e) =>
                recursiveImageId(f.fields, e)
              );
            } else {
              newData[f.name] = recursiveImageId(f.fields, newData[f.name]);
            }
          }
        }
        return newData;
      };
      const projectData = {
        ...this.data,
        groups: this.data.groups.map((e) => e.id),
        blocks: [],
      };
      for (let f of this.form) {
        const blocks =
          this.data.blocks[this.data.blocks.findIndex((e) => e.id === f.id)];
        projectData.blocks.push({
          ...blocks,
          data: recursiveImageId(f.fields, blocks.data),
        });
      }
      if (!this.haveErrors()) {
        if (this.id) {
          http.put(`/api/cases/${this.id}`, projectData).then((response) => {
            this.loading = false;
            if (response.status < 400) {
              this.$router.go(-1);
            }
          });
        } else {
          http.post(`/api/cases/`, projectData).then((response) => {
            this.loading = false;
            if (response.status < 400) {
              this.$router.go(-1);
            }
          });
        }
      } else
        document.dispatchEvent(
          new CustomEvent("loadPosition", {
            detail: {
              message: "Вы не заполнили требуемые данные",
              type: "error",
            },
          })
        );
    },
    haveErrors(txt = null) {
      let ch = false;
      for (let b in this.$refs) {
        if (this.$refs[b].length !== undefined) {
          this.$refs[b].forEach((e) => {
            if (ch === false) ch = e.checkReq(txt);
            else e.checkReq(txt);
          });
        } else {
          if (ch === false) {
            ch = this.$refs[b].checkReq(txt);
          } else {
            this.$refs[b].checkReq(txt);
          }
        }
      }
      return ch;
    },
    onChange(name, value, translate = false) {
      if (!translate) {
        this.data[name] = value;
      } else {
        const filteredArray = this.data[name].filter(
          (e) => e.lang !== this.language
        );
        this.data[name] = [
          ...filteredArray,
          {
            lang: this.language,
            value,
          },
        ];
      }
    },
  },
  created() {
    this.getGroups();
    this.collectBlocks();
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getPageInfo(this.id);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

section {
  display: grid;
  gap: ptr(50px);
}

.static-form {
  display: grid;
  gap: ptr(40px);
  width: 60%;
  grid-template-columns: repeat(2, max-content);
}

.top-block {
  display: flex;
  align-items: center;
  margin: 0 0 ptr(20px);
  h1 {
    margin: 0;
    font-size: ptr(36px);
    font-weight: 600;
    color: $h-color;
  }
}

h2 {
  margin: 0 0 ptr(20px);
  font-size: ptr(36px);
  font-weight: 500;
  color: $h-color;
}

.stck {
  margin-top: ptr(60px);
  padding: ptr(20px) 0;
  position: sticky;
  top: 0;
  z-index: 10;
  background: $back-color;
}

.static-content {
  display: flex;
  flex-wrap: wrap;
  max-width: ptr(700px);
  gap: ptr(30px) ptr(20px);
}
.mass-container {
  display: flex;
  flex-wrap: wrap;
  gap: ptr(30px) ptr(20px);
}
.control-btns {
  display: flex;
  align-items: center;
}
.active {
  box-shadow: 0 ptr(4px) ptr(7px) rgba(127, 170, 211, 0.3);
}
.blocks {
  margin: 0;
  h2 {
    margin: 0;
  }
  display: grid;
  gap: ptr(100px);
}
</style>
