var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('div',{staticClass:"top-block"},[_c('h1',[_vm._v("Кейс")]),_c('lang-selector',{on:{"get":function (e) { return (_vm.language = e); }}})],1),_c('div',{staticClass:"static-form"},[_c('input-container',{ref:"name",attrs:{"value":_vm.whichVal('name'),"options":{
        label: 'Название',
      }},on:{"get":function($event){return _vm.onChange('name', $event, true)}}}),_c('input-container',{ref:"url",attrs:{"value":_vm.data.url,"options":{
        label: 'Ссылка',
      }},on:{"get":function($event){return _vm.onChange('url', $event)}}}),_c('custom-selector',{attrs:{"options":{
        label: 'Группа',
        data: _vm.groups,
        required: true,
        kind: 'Array',
      },"selectedValue":_vm.data.groups},on:{"get":function($event){_vm.data.groups = $event}}}),_c('input-container',{ref:"description",attrs:{"value":_vm.whichVal('description'),"options":{
        label: 'Описание',
        type: 'Textarea',
      }},on:{"get":function($event){return _vm.onChange('description', $event, true)}}})],1),_c('div',{staticClass:"blocks"},_vm._l((_vm.form),function(f,k){return _c('ContentBlock',{key:f.id + k,ref:"blocks",refInFor:true,attrs:{"form":f,"block":_vm.data.blocks[_vm.getCurrentScheme(f.id)],"language":_vm.language,"outside":true},on:{"setData":function($event){return _vm.setBlocks($event, k, f.id)}}})}),1),_c('button',{staticClass:"add",on:{"click":_vm.saveAbout}},[_vm._v(" "+_vm._s(_vm.id ? "Обновить данные" : "Создать кейс")+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }