<template>
	<div class="lang-container">
		<div class="selector">
			<div
				v-for="(l, k) in langs"
				:key="k"
				@click="selectedLang = l"
				class="lang"
				:class="{ chosen: l.name === selectedLang.name }"
			>
				{{ l.name }}
			</div>
		</div>
		<div class="msg">
			Переключение <br> контента на сайте
		</div>
	</div>
</template>

<script>
export default {
	props: {
		langs: {
			default: () => [
				{
					name: "Ru",
					value: "ru-RU",
				},
				{
					name: "En",
					value: "en-US",
				},
			],
		},
	},
	data() {
		return {
			selectedLang: {
				name: "Ru",
				value: "ru-RU",
			},
		};
	},
	watch:{
		selectedLang(newVal){
			this.$emit("get",newVal.value);
		}	
	}
};
</script>

<style lang="scss" scoped>
	@import "../../components/styles/config.scss";

    .lang-container{
        display: flex;
        align-items: center;
        gap: ptr(10px);
        margin-left: ptr(50px);
    }
    .selector{
        display: flex;
        align-items: center;
        justify-content: center;
        height: ptr(28px);
        border-radius: $brd;
        overflow: hidden;
        border: 1px solid $border-color;
    }
    .lang{
        padding: 0 ptr(13px);
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: $trs;
        font-size: ptr(14px);
        font-weight: 500;
    }
    .chosen{
        background: $active-color;
        color: white;
    }
    .msg{
        font-size: ptr(12px);
        color: $text-color;
    }
</style>
