export default {
	methods: {
		getLangId(val) {
			return val.findIndex((e) => e.lang === this.language);
		},
		add(mass) {
			const obj = {};
			for (let f of mass) {
				if (f.type === "SubBlock") {
					if (f.name === "world" || f.name === "ru") {
						obj[f.name] = [];
					} else {
						obj[f.name] =
							f.kind === "Array"
								? [this.add(f.fields)]
								: this.add(f.fields);
					}
				} else if (f.kind === "Array") {
					obj[f.name] = [];
				} else if (
					f.type === "Image" ||
					f.type === "File" ||
					f.type === "Selector"
				) {
					obj[f.name] = null;
				} else if (f.type === "Checkbox") {
					obj[f.name] = false;
				} else if (f.type === "Number") {
					obj[f.name] = 0;
				} else if (f.languages.length > 0) {
					obj[f.name] = [
						{
							lang: f.languages[0],
							value: "",
						},
					];
				} else {
					obj[f.name] = "";
				}
			}
			return obj;
		},
		dataParse(str, back) {
			const checkZero = (d) => {
				return d > 9 ? d : "0" + d;
			};
			if (back) {
				let d = str.split(".");
				const tmz =
					new Date(d[2], d[1] - 1, d[0]).getTime() -
					new Date().getTimezoneOffset() * 60000;
				return new Date(tmz);
			}
			const d = new Date(str);
			return `${checkZero(d.getDate())}.${checkZero(
				d.getMonth() + 1
			)}.${d.getFullYear()}`;
		},
		setChekbox(name, set = null, subname, subId) {
			const val = this[this.objName];
			if (subId !== undefined) {
				val[name][subId][subname] = set;
			} else if (subname) {
				val[name][subname] = set;
			} else {
				val[name] = set;
			}
		},
		subToValue(name, subname, subId) {
			let val = this[this.objName][name];
			if (subId !== undefined) {
				val = val[subId];
			}
			if (subname) {
				val = val[subname];
			}
			return val;
		},
		getCurrentLangugeValue(obj) {
			return obj[this.getLangId(obj)];
		},
		whichVal(name, set = null, subname, subId) {
			const val = this.subToValue(name, subname, subId);
			const id = this.getLangId(val);
			if (set) {
				val[id].value = set;
				return;
			}
			return val[id].value;
		},
		whichImages(name, imgs, get = false) {
			const id = this.getLangId(this[this.objName][name]);
			if (get) {
				const isOne = imgs.length === undefined;
				if (id === -1) {
					if (isOne) {
						this[this.objName][name].push({
							id: imgs.id,
							lang: this.language,
						});
					} else {
						for (let i in imgs) {
							this[this.objName][name].push(i.id);
						}
					}
				} else {
					this[this.objName][name][id].id = isOne
						? imgs.id
						: imgs.map((e) => e.id);
				}
				return;
			}
			if (id >= 0) return this[this.objName][name][id];
			else return null;
		},
		labelToName(str) {
			let answer = "";
			const converter = {
				а: "a",
				б: "b",
				в: "v",
				г: "g",
				д: "d",
				е: "e",
				ё: "e",
				ж: "zh",
				з: "z",
				и: "i",
				й: "y",
				к: "k",
				л: "l",
				м: "m",
				н: "n",
				о: "o",
				п: "p",
				р: "r",
				с: "s",
				т: "t",
				у: "u",
				ф: "f",
				х: "h",
				ц: "c",
				ч: "ch",
				ш: "sh",
				щ: "sch",
				ь: "",
				ы: "y",
				ъ: "",
				э: "e",
				ю: "yu",
				я: "ya",

				А: "A",
				Б: "B",
				В: "V",
				Г: "G",
				Д: "D",
				Е: "E",
				Ё: "E",
				Ж: "Zh",
				З: "Z",
				И: "I",
				Й: "Y",
				К: "K",
				Л: "L",
				М: "M",
				Н: "N",
				О: "O",
				П: "P",
				Р: "R",
				С: "S",
				Т: "T",
				У: "U",
				Ф: "F",
				Х: "H",
				Ц: "C",
				Ч: "Ch",
				Ш: "Sh",
				Щ: "Sch",
				Ь: "",
				Ы: "Y",
				Ъ: "",
				Э: "E",
				Ю: "Yu",
				Я: "Ya",
			};

			for (let i = 0; i < str.length; ++i) {
				if (str[i] === " ") {
					answer += "_";
				} else if (converter[str[i]] == undefined) {
					answer += str[i];
				} else {
					answer += converter[str[i]];
				}
			}

			return answer;
		},
	},
};
