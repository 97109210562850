<template>
	<section>
		<div class="top-block">
			<h1>Создать новый блок</h1>
		</div>
		<create-block-component
			:blockTypes="blockTypes"
			:blockStructure="blockStructure"
			@setData="getData"
		/>
		<div class="btns">
			<button @click="setBlock" class="add">
				{{ changeMode ? "Обновить данные" : "Создвть блок" }}
			</button>
			<button v-if="changeMode" @click="deleteBlock" class="delete">
				Удалить
			</button>
		</div>
	</section>
</template>

<script>
import { http } from "../components/AuthService/httpService";
import CreateBlockComponent from "@/components/BlocksCreate/CreateBlockComponent.vue";
export default {
	components: { CreateBlockComponent },
	data() {
		return {
			language: "ru-RU",
			blockTypes: [
				{
					name: "Текстовая строка",
					type: "Text",
				},
				{
					name: "Число",
					type: "Number",
				},
				{
					name: "Текстовое поле",
					type: "Textarea",
				},
				{
					name: "Телефон",
					type: "Phone",
				},
				{
					name: "Email",
					type: "Email",
				},
				{
					name: "Дата",
					type: "Date",
				},
				{
					name: "Время",
					type: "Time",
				},
				{
					name: "Дата и время",
					type: "DateTime",
				},
				{
					name: "Файл",
					type: "File",
				},
				{
					name: "Изображение",
					type: "Image",
				},
				{
					name: "Селектор",
					type: "Select",
				},
				{
					name: "Чекбокс",
					type: "Checkbox",
				},
				{
					name: "Вложенный блок",
					type: "SubBlock",
				},
			],
			blockStructure: {
				name: "",
				label: "",
				fields: [],
			},
			changeMode: false,
		};
	},
	methods: {
		getData(data) {
			this.blockStructure = data;
		},
		setBlock() {
			if (this.changeMode) {
				http.put(
					"/api/blocks/" + this.blockStructure.id,
					this.blockStructure
				);
			} else {
				http.post("/api/blocks/", this.blockStructure).then(
					(response) => {
						if (response.status < 400) {
							this.$router.go(-1);
						}
					}
				);
			}
		},
		deleteBlock() {
			http.delete("/api/blocks/" + this.blockStructure.id).then(
				(response) => {
					if (response.status < 400) {
						this.$router.go(-1);
					}
				}
			);
		},
	},
	mounted() {
		if (this.$route.query.structure) {
			this.changeMode = true;
			this.blockStructure = this.$route.query.structure;
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

section {
	display: flex;
	flex-direction: column;
}

.top-block {
	display: flex;
	align-items: center;
	margin: 0 0 ptr(20px);
	h1 {
		margin: 0;
		font-size: ptr(36px);
		font-weight: 600;
		color: $h-color;
	}
}

h2 {
	margin: 0 0 ptr(20px);
	font-size: ptr(36px);
	font-weight: 500;
	color: $h-color;
}

.stck {
	margin-top: ptr(60px);
	padding: ptr(20px) 0;
	position: sticky;
	top: 0;
	z-index: 10;
	background: $back-color;
}
.static-content {
	display: flex;
	flex-wrap: wrap;
	max-width: ptr(700px);
	gap: ptr(30px) ptr(20px);
}
.mass-container {
	display: flex;
	flex-wrap: wrap;
	gap: ptr(30px) ptr(20px);
}
.control-btns {
	display: flex;
	align-items: center;
}
.active {
	box-shadow: 0 ptr(4px) ptr(7px) rgba(127, 170, 211, 0.3);
}
.blocks {
	margin: 0;
	h2 {
		margin: 0;
	}
	display: grid;
	gap: ptr(50px);
}
</style>
