<template>
	<div class="photo-loader">
		<div class="label-info">
			{{ options.label }}
			<div v-if="options.validation.required" class="req">
				*
			</div>
			<div v-if="error.catch" class="error-text">
				{{ error.text }}
			</div>
			<span v-if="options.minContent" class="min-size"
				>Мин размер {{ options.minContent }}px</span
			>
			<span
				v-if="
					options.validation.maxCount ||
						options.validation.minCount ||
						options.validation.exactCount
				"
				class="min-size"
				>{{ photos.length }} /
				{{
					options.validation.maxCount ||
						options.validation.minCount ||
						options.validation.exactCount
				}}</span
			>
		</div>
		<div class="photos-container">
			<label>
				<input v-if="options.onlyOne" @change="addPhotos" type="file" />
				<input v-else @change="addPhotos" multiple type="file" />
				<div class="custom-file-get" :class="{ requared: error.catch }">
					Выбрать файлы
				</div>
			</label>
			<div
				v-for="(p, k) in photos"
				:key="k"
				class="photo"
				:class="{
					loaded: p.loaded,
					drag: photoDrag === k,
					over: photoOver === k,
				}"
				draggable="true"
				@dragover.prevent
				@drop="drop(p.sort)"
				@dragleave="dragLeave()"
				@dragend="dragEnd"
				@dragenter="photoOver = p.sort"
				@dragstart="photoDrag = p.sort"
			>
				<img
					@click="slice(k)"
					class="close"
					src="/static/icons/close.svg"
					alt=""
				/>
				<video
					v-if="p.type === 'video'"
					:src="p.url"
					class="content"
					muted
					autoplay
					loop
				/>
				<img
					v-else-if="p.type === 'image'"
					:src="p.url"
					class="content"
					alt=""
				/>
				<span v-else>
					{{ p.url }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import { http } from "../../components/AuthService/httpService";
export default {
	props: {
		options: {
			default: () => ({
				validations: {},
				params: {},
			}),
		},
		images: {
			default: null,
		},
	},
	data() {
		return {
			photos: [],
			photoOver: null,
			photoDrag: null,
			error: {
				catch: false,
				text: "",
			},
		};
	},
	methods: {
		dragLeave() {
			this.photoOver = this.photoDrag;
		},
		dragEnd() {
			if (this.photoOver !== null) {
				this.clearDrag();
			}
		},
		clearDrag() {
			this.photoOver = null;
			this.photoDrag = null;
		},
		drop() {
			if (this.photoOver === this.photoDrag) {
				this.clearDrag();
				return;
			}
			this.photos[this.photoDrag].sort = this.photoOver;
			this.photos[this.photoOver].sort = this.photoDrag;
			this.photos.sort((a, b) => a.sort - b.sort);
			this.clearDrag();
			this.emitIds();
		},
		addPhotos(e) {
			this.error.catch = false;
			if (e.target.files[0]) {
				if (this.options.onlyOne) {
					this.photos = [
						{
							name: e.target.files[0].name,
							url: "Загрузка",
							id: "-1",
							loaded: false,
						},
					];
					this.loadImage(e.target.files[0], 0);
				} else {
					this.photos.push(
						...[...e.target.files].map((e, k) => {
							this.loadImage(e, k + this.photos.length);
							return {
								name: e.name,
								url: "Загрузка",
								id: "-1",
								loaded: false,
							};
						})
					);
				}
			}
		},
		emitIds() {
			if (this.options.onlyOne) {
				this.$emit("photoId", this.photos[0] ? this.photos[0] : null);
			} else {
				this.$emit("photoId", this.photos);
			}
		},
		checkReq() {
			const checkValidation = (validations, data) => {
				const error = {
					catch: false,
					text: "",
				};
				for (let v in validations) {
					switch (v) {
						case "required":
							error.catch = data.length === 0;
							error.text = "Вы не заполнили данные";
							break;
						case "maxCount":
							error.catch = data.length > validations[v];
							error.text = "Привышение максимального размера";
							break;
						case "minCount":
							error.catch = data.length < validations[v];
							error.text = `Колличество ниже минимального (Минимум ${validations[v]})`;
							break;
						case "extact":
							error.catch = data.length !== validations[v];
							error.text = `Нужно конкретное колличество (${validations[v]})`;
							break;
					}
					if (error.catch) {
						return error;
					}
				}
				return error;
			};
			const errorRes = checkValidation(
				this.options.validation,
				this.photos
			);
			this.error = errorRes;
			return errorRes.catch;
		},
		slice(k) {
			this.photos.splice(k, 1);
			if (this.photos.length === 0) {
				this.$el.querySelector("input").value = "";
			}
			this.emitIds();
		},
		loadImage(file, k) {
			const formData = new FormData();
			formData.append("file", file);
			http.post("/api/files", formData).then((response) => {
				if (response.status < 400) {
					this.photos[k].loaded = true;
					this.photos[k].id = response.data.id;
					this.photos[k].url = response.data.url;
					this.photos[k].type = this.fileType(response.data.url);
					this.emitIds();
				}
			});
		},
		fileType(url) {
			const ext = url.split(".");
			let type = "";
			switch (ext[ext.length - 1]) {
				case "webp":
				case "jp2":
				case "jpg":
				case "png":
				case "svg":
					type = "image";
					break;
				case "mp4":
					type = "video";
					break;
				default:
					type = "files";
					break;
			}
			return type;
		},
		setPhotos(val) {
			if (this.options.onlyOne) {
				const v = val.length === undefined ? val : val[0];
				this.photos = [
					{
						id: v.id,
						url: v.url,
						loaded: true,
						sort: 0,
						type: this.fileType(v.url),
					},
				];
			} else {
				this.photos = val.map((e, k) => {
					return {
						id: e.id,
						url: e.url,
						loaded: true,
						sort: k,
						type: this.fileType(e.url),
					};
				});
			}
			this.emitIds();
		},
		checkVal(val, past) {
			const pastWasnt = () => {
				return past === undefined || past === null;
			};
			if (val === null) {
				this.photos = [];
			} else if (!this.options.onlyOne) {
				if (
					pastWasnt() ||
					val.length !== past.length ||
					val.some((e, k) => e.id !== past[k].id)
				) {
					this.setPhotos(val);
				}
			} else if (pastWasnt() || val.id !== past.id) {
				this.setPhotos(val);
			}
		},
	},
	watch: {
		images(newVal, pastVal) {
			this.checkVal(newVal, pastVal);
		},
	},
	mounted() {
		this.checkVal(this.images);
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";

.photo-loader {
	display: grid;
	grid-column: span 2;
	width: ptr(660px);
}

.min-size {
	margin-left: ptr(25px);
	color: $subtext-color;
}

.photos-container {
	display: flex;
	flex-wrap: wrap;
	gap: ptr(10px);
}

label {
	input {
		display: none;
	}
}
.custom-file-get {
	display: flex;
	align-items: center;
	justify-content: center;
	height: ptr(33px);
	height: ptr(80px);
	width: ptr(120px);
	border-radius: $brd;
	font-size: ptr(14px);
	color: $text-color;
	border: 1px solid $border-color;
	cursor: pointer;
}
.photo {
	background: rgb(0, 0, 0);
	border-radius: $brd;
	color: white;
	height: ptr(80px);
	width: ptr(120px);
	transition: $trs;
	overflow: hidden;
	font-weight: 500;
	position: relative;
	cursor: move;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		.close {
			opacity: 1;
		}
	}
}
.drag {
	opacity: 0.5;
}
.over {
	box-shadow: 0 0 0 ptr(4px) $active-color;
}
.close {
	cursor: pointer;
	position: absolute;
	top: ptr(7px);
	right: ptr(7px);
	width: ptr(10px);
	z-index: 2;
	transition: $trs;
	opacity: 0;
}
.content {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: contain;
	pointer-events: none;
}
.hover-img {
	position: absolute;
	right: 0;
	top: 50%;
	opacity: 0;
	z-index: 2;
	pointer-events: none;
	background: $border-color;
	img {
		width: ptr(200px);
		object-fit: fill;
		margin: 0;
	}
	transition: $trs;
}
.loaded {
	background: #5c5c5c;
}
.red {
	color: $red;
	margin-left: ptr(3px);
}
</style>
