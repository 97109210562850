<template>
	<label @change="setVal">
		<input :checked="value" type="checkbox" />
		<div class="custom-checkbox"></div>
		{{ label }}
	</label>
</template>

<script>
export default {
	props: {
		label: {
			default: "Название",
		},
		value: {
			default: false,
		},
		startByTrue: {
			default: false,
		},
	},
	methods: {
		setVal(e) {
			this.$emit("get", e.target.checked);
		},
	},
	mounted() {
		this.$emit("get", this.startByTrue);
	},
};
</script>

<style lang="scss" scoped>
@import "@/components/styles/config.scss";

label {
	display: flex;
	width: fit-content;
	align-items: center;
	input {
		display: none;
		&:checked + .custom-checkbox {
			background: $active-color;
		}
	}
	color: $text-color;
	font-size: ptr(16px);
	font-weight: 500;
	cursor: pointer;
}
.custom-checkbox {
	width: ptr(14px);
	height: ptr(14px);
	border: ptr(2px) solid $active-color;
	border-radius: ptr(3px);
	flex-shrink: 0;
	background: white;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: ptr(18px);
	transition: $trs;
	&::after {
		content: "";
		position: absolute;
		background-image: url("/static/icons/checked.svg");
		background-size: cover;
		width: ptr(14px);
		transition: $trs;
		height: ptr(13px);
	}
}
</style>
