<template>
	<div class="block-selector" :class="`size-${options.size}`">
		<div class="label-info">
			{{ options.label }}
			<div v-if="options.required" class="req">
				*
				<div v-if="errorText" class="error-text">{{ errorText }}</div>
			</div>
		</div>
		<div
			class="selector-container"
			tabindex="0"
			@click="
				openCards = true;
				errorText = '';
			"
			@blur="openCards = false"
		>
			<div class="selected-value">
				<template v-if="currentValues.value.length > 0">
					<div v-if="options.kind === 'Array'" class="mass-values">
						<span
							v-for="(el, k) of optionsValue()"
							:key="k"
							class="select-value"
						>
							{{ el.name }}
							<img
								src="/static/icons/close.svg"
								alt=""
								@click="unselect(el)"
							/>
						</span>
					</div>
					<span v-else>
						{{ optionsValue() }}
					</span>
				</template>
				<span v-else>
					Выберете значение
				</span>
				<img src="/static/icons/arrow.svg" alt="" class="arrow-down" />
			</div>
			<div v-if="openCards" class="options-container">
				<div
					v-for="(e, k) in options.data"
					:key="k"
					@click="select(e)"
					class="opt"
				>
					{{
						options.inValue
							? whichVal("all", null, false, k)
							: e.name
					}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import inputHandlerMixin from "@/mixins/inputHandlerMixin";

export default {
	mixins: [inputHandlerMixin],
	props: {
		options: {
			default: {
				data: [],
				label: "",
				size: 2,
				inValue: true,
				required: false,
				kind: "Single",
			},
		},
		language: {
			default: "ru-RU",
		},
		selectedValue: {
			default: null,
		},
	},
	data() {
		return {
			openCards: false,
			objName: "currentValues",
			errorText: "",
			error: false,
			currentValues: {
				all: [],
				value: [],
			},
		};
	},
	methods: {
		select(e) {
			if (this.options.kind === "Single") {
				this.currentValues.value = [e];
				this.$emit("get", e);
			} else if (!this.currentValues.value.some((v) => v.id === e.id)) {
				this.currentValues.value.push(e);
				this.$emit("get", this.currentValues.value);
			}
		},
		unselect(e) {
			const id = this.currentValues.value.findIndex(
				(v) => v.name === e.name
			);
			this.currentValues.value.splice(id, 1);
			this.$emit("get", this.currentValues.value);
		},
		optionsValue() {
			let res;
			if (this.options.inValue) {
				res = this.currentValues.value.map((e, k) =>
					this.whichVal("value", null, k)
				);
			} else {
				res = this.currentValues.value;
			}
			return res;
		},
		checkReq(errComponent) {
			if (!this.options.required || !errComponent) return false;
			this.error = true;
			if (errComponent) {
				this.errorText = errComponent.txt;
			} else {
				this.errorText = "Вы не указали данные";
			}
			return true;
		},
	},
	watch: {
		selectedValue() {
			this.currentValues.all = this.options.data;
			if (this.selectedValue !== null) {
				this.currentValues.value = this.selectedValue;
			}
		},
	},
	beforeMount() {
		this.currentValues.all = this.options.data;
		if (this.selectedValue !== null) {
			this.currentValues.value = this.selectedValue;
		}
	},
};
</script>

<style lang="scss" scoped>
@import "../../components/styles/config.scss";
.block-selector {
	display: grid;
	gap: ptr(10px);
	width: 100%;
	position: relative;
}
.selected-value {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}

.arrow-down {
	position: absolute;
	width: ptr(15px);
	right: ptr(15px);
	opacity: 0.5;
}

.mass-values {
	display: flex;
	gap: ptr(5px);
}

.select-value {
	background: #68c9ff;
	border-radius: ptr(4px);
	padding: ptr(4px) ptr(6px);
	color: white;
	font-size: ptr(14px);
	position: relative;
	display: inline-flex;
	align-items: center;
	gap: ptr(6px);
	img {
		width: ptr(10px);
		height: ptr(10px);
		margin-top: ptr(2px);
	}
}

.options-container {
	position: absolute;
	z-index: 2;
	top: 100%;
	left: 0;
	width: calc(100% - 2rem / 16);
	border-radius: 0 0 $brd $brd;
	background: white;
	border-top: 1px solid transparent;
	border-left: 1px solid $border-color;
	border-right: 1px solid $border-color;
	border-bottom: 1px solid $border-color;
}
.selector-container {
	border: 1px solid $border-color;
	border-radius: $brd;
	width: 100%;
	box-sizing: border-box;
	height: ptr(45px);
	padding: 0 0 0 ptr(20px);
	display: flex;
	align-items: center;
	transition: $trs;
	background: white;
	&:focus-within {
		border: 1px solid $active-color;
	}
	input {
		background: none;
		border: none;
		height: 100%;
		width: 100%;
		font-size: ptr(14px);
		font-weight: 500;
		font-family: $main-font;
		color: black;
		&:focus {
			outline: none;
		}
	}
}

.opt {
	padding: ptr(10px) ptr(20px);
	font-weight: 500;
	font-size: ptr(16px);
	cursor: pointer;
	transition: $trs;
	&:hover {
		background: $active-color;
		color: white;
	}
}

.size-1 {
	width: ptr(220px);
}
.size-2 {
	width: ptr(340px);
}
.size-3 {
	width: ptr(700px);
}
</style>
