<template>
	<div
		class="project-group"
		@mousemove="moveEvent"
		@mouseup="ungrabEvent"
		@mouseleave="ungrabEvent"
	>
		<div class="table" :class="{ moving: movingTarget.el }">
			<div
				v-for="(e, k) in group.projects"
				:key="e.id"
				@mousemove="moveOtherEvent($event, k, 'dragableProjects')"
				class="move-component draggable"
			>
				<div
					@mousedown="grabEvent($event, k, 'dragableProjects')"
					class="drag-icon"
				></div>
				<div class="artist-name">
					{{ e.name }}
				</div>
				<div class="status">
					{{
						!e.isPublished
							? "Скрыт"
							: e.inProgress
							? "Не активен"
							: "Активен"
					}}
				</div>
				<div class="changed-date">
					{{ parseDate(e.updatedAt) }}
				</div>
				<options-component
					:data="e"
					:groups="groups"
					@getAllProjects="$emit('getAllProjects')"
				></options-component>
			</div>
		</div>
	</div>
</template>

<script>
import dragMixin from "@/mixins/dragMixin";
import OptionsComponent from "@/components/OptionsComponent";
import { http } from "@/components/AuthService/httpService";
export default {
	mixins: [dragMixin],
	components: { OptionsComponent },
	props: {
		group: {
			default: () => ({
				id: 0,
				projects: [],
			}),
		},
		groups: {
			default: () => [],
		},
	},
	data() {
		return {
			dragableProjects: [],
		};
	},
	methods: {
		saveSort(name) {
			http.put(`/api/case-groups/${this.group.id}/sort`, {
				items: this[name],
			}).then((response) => {
				if (response.status < 400) {
					this.$emit("getAllProjects");
				}
			});
		},
	},
	mounted() {
		this.dragableProjects = this.group.projects;
	},
};
</script>

<style lang="scss" scoped>
@import "@/components/styles/config.scss";
@import "@/components/styles/defaultPage.scss";
</style>
