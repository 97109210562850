<template>
	<section>
		<div class="content-panel">
			<div class="top-info">
				<img :src="project.img" alt="" />
				<div class="text-container">
					<h2>
						{{ project.name }}
					</h2>
					<span>
						admin panel
					</span>
				</div>
			</div>
			<div class="input-container">
				<div class="label-info">Логин</div>
				<input v-model="login" />
			</div>
			<div class="input-container">
				<div class="label-info">Пароль</div>
				<input v-model="password" type="password" />
			</div>
			<button @click="Authorize">
				Войти
			</button>
		</div>
	</section>
</template>

<script>
import { authService } from "../components/AuthService/authService";
export default {
	props: {
		project: {
			default: () => ({
				name: "",
				logo: "",
			}),
		},
	},
	data() {
		return {
			login: "",
			password: "",
		};
	},
	methods: {
		Authorize() {
			authService.authorize(this.login, this.password).then(() => {
				this.$router.push("/");
			});
		},
	},
	mounted() {
		this.unsubscribe = authService.subscribe("auth", () => {
			setTimeout(
				() => {
					this.$emit("in");
				},
				this.auth ? 3000 : 0
			);
		});
	},
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

section {
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	left: 0;
	top: 0;
	width: 100vw;
	height: 100vh;
	z-index: 2;
	background: #fbfcff;
}

.content-panel {
	width: ptr(480px);
	height: ptr(480px);
	padding: ptr(60px) ptr(90px) ptr(75px);
	box-sizing: border-box;
	background: white;
	border: 1px solid $border-color;
	display: grid;
	// grid-auto-rows: min-content;
	justify-content: center;
	gap: ptr(20px);
}

.top-info {
	display: flex;
	align-items: center;
	img {
		width: ptr(43px);
	}
}

.text-container {
	margin-left: ptr(20px);
	h2 {
		font-size: ptr(28px);
		margin: 0;
	}
	span {
		font-size: ptr(22px);
		margin-top: ptr(6px);
	}
}

.input-container {
	display: grid;
	gap: ptr(10px);
}
.label-info {
	color: #5671ba;
	font-size: ptr(16px);
	font-weight: 500;
	span {
		margin-left: ptr(5px);
		color: $red;
	}
}
input {
	border-radius: $brd;
	background: white;
	border: 1px solid $border-color;
	height: ptr(45px);
	padding: 0 0 0 ptr(20px);
	transition: $trs;
	font-size: ptr(16px);
	font-weight: 500;
	font-family: $main-font;
	box-sizing: border-box;
	&::placeholder {
		color: $subtext-color;
	}
	&:focus {
		outline: none;
		border: 1px solid $active-color;
	}
}
button {
	background: $active-color;
	color: white;
	font-size: ptr(14px);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: $brd;
	height: ptr(47px);
	font-size: ptr(14px);
	font-weight: 600;
	margin-top: ptr(12px);
}
</style>
