import axios from "axios";

const ACCESS_KEY = "@mag_A6b7258334b5B0020D1078c24DcABE5d_a";
const REFRESH_KEY = "@mag_145dC1da0ede8E020f33263dCE4Dfc33_r";
const REFRESH_LOCK_KEY = "@mag_3b0596BCFDd27F01b286FCbBF9988841_lock";
const LAST_REFRESH_TIMESTAMP_KEY =
	"@mag_779fFe6CC87E48fDd39F47514660543A_r_timestamp";
const REFRESH_TIMEOUT = 2000; // 2s

class AuthService {
	_scheme = "Bearer";
	_authPath = "/api/users/auth";
	_refreshPath = "/api/users/refresh";
	_logoutPath = "/api/users/logout";
	_usernameField = "userName";

	_isRefreshLocked = false;
	_parsedAccessToken = null;
	_lastParsedAccessToken = null;
	_authorizationPromise = null;
	_autoRefresherTimer = null;
	_lockPromise = null;
	_subs = new Set();

	get accessToken() {
		return localStorage.getItem(ACCESS_KEY) || null;
	}

	get refreshToken() {
		return localStorage.getItem(REFRESH_KEY) || null;
	}

	get parsedAccessToken() {
		if (
			this.accessToken != null &&
			this.accessToken != this._lastParsedAccessToken
		) {
			this._parsedAccessToken = this.parseJwtToken(this.accessToken);
			this._lastParsedAccessToken = this.accessToken;
		}

		return this._parsedAccessToken;
	}

	get isAuth() {
		return (
			this.accessToken != null &&
			this.parsedAccessToken.exp * 1000 > Date.now()
		);
	}

	get isAuthInProgress() {
		return this.isRefreshLocked || this._authorizationPromise != null;
	}

	get isRefreshLocked() {
		return (
			localStorage.getItem(REFRESH_LOCK_KEY) === "1" ||
			this._isRefreshLocked
		);
	}

	get lastRefreshTimestamp() {
		return parseInt(
			localStorage.getItem(LAST_REFRESH_TIMESTAMP_KEY) || "0"
		);
	}

	set lastRefreshTimestamp(value) {
		localStorage.setItem(LAST_REFRESH_TIMESTAMP_KEY, value.toString());
	}

	constructor() {
		this.reqMiddleware = this.reqMiddleware.bind(this);
		this.resMiddleware = this.resMiddleware.bind(this);
	}

	init() {
		if (
			this.isAuth ||
			this.refreshToken == null ||
			this.refreshToken === ""
		) {
			if (this.isAuth && this._autoRefresherTimer == null) {
				this.setupAutoRefresher();
			}
		} else {
			this.refreshTokens();
		}

		this.setupLockListener();
	}

	/***
	 * Subscribes to authorization event
	 * @param event - event to subscribe ('auth' or 'logout')
	 * @param callback function called on auth
	 * @param initialOnly call function only once
	 * @returns {function(...[*]=)} function to unsubscribe to event
	 */
	subscribe(event, callback, initialOnly = false) {
		let fired = false;

		if (this.isAuth && event === "auth") {
			callback();
			fired = true;
		}

		const subInfo = { event, callback, initialOnly, fired };

		this._subs.add(subInfo);

		return () => {
			this._subs.delete(subInfo);
		};
	}

	/***
	 * Authorize this client
	 * @param username - username, email, etc. (depends on server config)
	 * @param password
	 * @returns {Promise<void>}
	 */
	async authorize(username, password) {
		const response = await axios.post(
			this._authPath,
			{
				[this._usernameField]: username,
				password,
			},
			{ headers: { Accept: "application/json" } }
		);

		if (response.status < 400) {
			this.storeTokens(
				response.data.accessToken,
				response.data.refreshToken
			);

			this.lastRefreshTimestamp = Date.now();

			this.setupAutoRefresher();

			this.dispatch("auth");

			this.log("Authorized as " + username);
			document.dispatchEvent(new CustomEvent("login"));
		} else {
			alert(response.title);
			this.log(
				`Failed to authorize: ${response.status} ${response.statusText}`
			);
		}
	}

	/***
	 * Logout this client
	 * @returns {Promise<void>}
	 */
	async logout() {
		this.clearTokens();
	}

	/***
	 * Refresh JWT tokens
	 * @returns {Promise<void>}
	 */
	async refreshTokens() {
		try {
			if (this.isRefreshLocked) {
				this.log(
					"[refreshTokens]: refresh is locked! Aborting refresh..."
				);
				return;
			}

			this.lockRefresh();

			const response = await axios.post(
				this._refreshPath,
				{
					refreshToken: this.refreshToken,
				},
				{ headers: { Accept: "application/json" } }
			);

			if (response.status < 400) {
				this.storeTokens(
					response.data.accessToken,
					response.data.refreshToken
				);

				this.lastRefreshTimestamp = Date.now();

				this.setupAutoRefresher();
				this.dispatch("auth");

				this.log("Tokens refreshed!");
			} else if (response.status === 400) {
				await this.logout();
			}
		} catch (e) {
			this.log(`[catch] Failed to refresh: ` + e.toString());
		} finally {
			this.releaseRefresh();
		}
	}

	async reqMiddleware(req) {
		document.dispatchEvent(
			new CustomEvent("loadPosition", {
				detail: { type: "start" },
			})
		);

		await this.authorizationCheck();

		if (this.isAuth) {
			if (!req.headers) req.headers = {};

			req.headers["Authorization"] =
				this._scheme + " " + this.accessToken;
		}

		return req;
	}

	async resMiddleware(res) {
		let status;
		if (res.status < 400) {
			status = "succes";
		} else if (res.status >= 400 && res.status < 500) {
			if (res.status === 401) {
				window.location = "/auth";
			}
			status = "error";
		} else status = "serverError";

		document.dispatchEvent(
			new CustomEvent("loadPosition", {
				detail: { ...res.data, type: status },
			})
		);

		return res;
	}

	dispatch(event) {
		let subsToRemove = [];

		this._subs.forEach((sub) => {
			if ((!sub.initialOnly || !sub.fired) && sub.event === event) {
				sub.callback();
				sub.fired = true;

				if (sub.initialOnly) {
					subsToRemove.push(sub);
				}
			}
		});

		subsToRemove.forEach((s) => this._subs.delete(s));
	}

	authorizationCheck() {
		if (this._authorizationPromise != null) {
			return this._authorizationPromise;
		}

		this._authorizationPromise = new Promise(async (resolve, reject) => {
			await this.checkRefreshLock();

			if (this.isAuth) {
				resolve();

				this._authorizationPromise = null;

				return;
			}

			if (this.refreshToken == null || this.refreshToken === "") {
				reject(new Error("Unauthorized: no refresh token"));

				this._authorizationPromise = null;

				window.location = "/auth";
				return;
			}

			this.log("Unauthorized! Starting refresh...");

			try {
				await this.refreshTokens();
				resolve();
			} catch (e) {
				reject(e);
			} finally {
				this._authorizationPromise = null;
			}
		});

		return this._authorizationPromise;
	}

	parseJwtToken(token) {
		const payload = token
			.split(".")[1]
			.replace(/-/g, "+")
			.replace(/_/g, "/");

		const decoded = decodeURIComponent(
			atob(payload)
				.split("")
				.map((c) => {
					return (
						"%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
					);
				})
				.join("")
		);

		return JSON.parse(decoded);
	}

	storeTokens(accessToken, refreshToken) {
		localStorage.setItem(ACCESS_KEY, accessToken);
		localStorage.setItem(REFRESH_KEY, refreshToken);
	}

	clearTokens() {
		localStorage.removeItem(ACCESS_KEY);
		localStorage.removeItem(REFRESH_KEY);
	}

	setupAutoRefresher() {
		if (this.isRefreshLocked || this._autoRefresherTimer != null) {
			return;
		}

		// Random timer offset to reduce chances
		// of multiple tabs causing parallel tokens refresh
		const randomTimeOffset = Math.round(5000 * Math.random());

		// 5s before token expiration
		const timeoutTime =
			this.parsedAccessToken.exp * 1000 -
			Date.now() -
			5000 -
			randomTimeOffset;

		this._autoRefresherTimer = setTimeout(async () => {
			this.log("AutoRefresher is fired");

			this.clearAutoRefresher();

			if (this.isRefreshLocked) {
				this.log("AutoRefresher dismissed (refresh is locked)");

				return;
			} else if (Date.now() - this.lastRefreshTimestamp < 60 * 1000) {
				this.log("AutoRefresher dismissed (refresh is locked)");

				if (this.isAuth) {
					this.setupAutoRefresher();
				}

				return;
			}

			await this.refreshTokens();

			this.log("AutoRefresher finished work");
		}, timeoutTime);

		this.log("AutoRefresher is set for " + timeoutTime + " ms");
	}

	setupLockListener() {
		window.addEventListener("storage", (e) => {
			this.log("Storage event");

			if (e.key !== REFRESH_LOCK_KEY) {
				return;
			}

			this.onRefreshLockToggle(e.newValue == null);
		});

		this.log("Lock listener is set");
	}

	clearAutoRefresher() {
		if (this._autoRefresherTimer != null) {
			clearTimeout(this._autoRefresherTimer);
			this._autoRefresherTimer = null;
		}
	}

	lockRefresh() {
		localStorage.setItem(REFRESH_LOCK_KEY, "1");
		this.onRefreshLockToggle();
	}

	releaseRefresh() {
		localStorage.removeItem(REFRESH_LOCK_KEY);
		this.onRefreshLockToggle(true);
	}

	onRefreshLockToggle(unlock = false) {
		if (unlock) {
			this._isRefreshLocked = false;

			this.log("[Lock event]: Released lock");

			if (this.isAuth) {
				this.setupAutoRefresher();
			}
		} else {
			this.log("[Lock event]: Locked");

			this._isRefreshLocked = true;
		}
	}

	checkRefreshLock() {
		if (!this.isRefreshLocked) {
			return new Promise((resolve) => resolve());
		}

		this.log("Refresh is locked! Awaiting...");

		if (this._lockPromise != null) {
			return this._lockPromise;
		}

		this._lockPromise = new Promise((resolve, reject) => {
			const interval = 50;
			let timePassed = 0;

			let checkInterval = setInterval(() => {
				if (!this.isRefreshLocked) {
					this.log("Refresh is released! Resolving...");

					clearInterval(checkInterval);

					resolve();

					this._lockPromise = null;
				} else if (timePassed >= REFRESH_TIMEOUT) {
					clearInterval(checkInterval);

					reject(new Error("Refresh timeout"));

					this._lockPromise = null;
					this.releaseRefresh();
				}

				timePassed += interval;
			}, interval);
		});

		return this._lockPromise;
	}

	havePermision(permision) {
		return this.accessToken.parsedAccessToken.Permission.include(permision);
	}

	log(message) {
		console.log(`[${Date.now()}]: ${message}`);
	}
}

const authService = new AuthService();

authService.init();

export { authService };
