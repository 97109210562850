<template>
	<div tabindex="0" class="options">
		<div class="settings-component">
			<router-link :to="'/case/' + data.id" class="opt">
				Редактировать
			</router-link>
			<div class="opt subs">
				Сменить папку
				<div class="sub-option">
					<div
						v-for="(g, index) in groups"
						:key="index"
						class="opt"
						@click="setToNewGroup(g.id)"
					>
						{{ g.name }}
					</div>
				</div>
			</div>
			<div @click="deleteProject(data.id)" class="opt">
				Удалить
			</div>
			<div @click="changeStatus(true)" class="opt">
				{{ data.inProgress ? "Активен" : "В процессе" }}
			</div>
			<div @click="changeStatus(false)" class="opt">
				{{ data.isPublished ? "Скрыть" : "Отображать" }}
			</div>
		</div>
		<img src="/static/icons/kebab-menu.svg" alt="" />
	</div>
</template>

<script>
import { http } from "./AuthService/httpService";
export default {
	props: {
		data: {
			default: () => ({
				id: 0,
				inProgress: false,
				IsPublished: false,
			}),
		},
		groups: {
			default: () => [],
		},
	},
	methods: {
		deleteProject(id) {
			http.delete("/api/cases/" + id).then((response) => {
				if (response.status < 400) {
					this.$emit("getAllProjects");
				}
			});
		},
		setToNewGroup(id) {
			http.put(`/api/case-groups/${id}/add`, {
				caseId: this.data.id,
			}).then(() => {
				this.$emit("getAllProjects");
			});
		},
		changeStatus(progress) {
			http.put(`/api/cases/${this.data.id}/status/`, {
				inProgress: progress
					? !this.data.inProgress
					: this.data.inProgress,
				isPublished: progress
					? this.data.isPublished
					: !this.data.isPublished,
			}).then((response) => {
				if (response.status < 400) {
					this.$emit("getAllProjects");
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/config.scss";
.options {
	display: flex;
	align-items: center;
	justify-content: center;
	height: ptr(40px);
	position: relative;
	cursor: pointer;
	img {
		width: ptr(24px);
	}
	&:focus-within {
		.settings-component {
			opacity: 1;
			pointer-events: all;
		}
	}
}
.settings-component {
	position: absolute;
	width: ptr(198px);
	border-radius: $brd;
	border: 1px solid $border-color;
	color: $text-color;
	z-index: 200;
	right: 120%;
	top: ptr(-6px);
	opacity: 0;
	pointer-events: none;
	transition: $trs;
	&::after {
		position: absolute;
		content: "";
		border-radius: ptr(3px);
		width: ptr(12px);
		height: ptr(12px);
		background: rgb(255, 255, 255);
		border: 1px solid $border-color;
		right: ptr(-5px);
		top: ptr(18px);
		transform: rotate(45deg);
	}
}

.sub-option {
	border-radius: $brd;
	border: 1px solid $border-color;
	width: ptr(198px);
	position: absolute;
	opacity: 0;
	pointer-events: none;
	right: calc(100% + #{ptr(20px)});
	top: 0;
	&::after {
		content: "";
		position: absolute;
		left: 100%;
		top: 0;
		width: ptr(20px);
		height: 100%;
	}
}

.subs {
	&:hover .sub-option {
		pointer-events: all;
		opacity: 1;
	}
}

.opt {
	height: ptr(46px);
	display: flex;
	align-items: center;
	padding: 0 ptr(20px);
	font-size: ptr(16px);
	background: white;
	position: relative;
	z-index: 2;
	font-weight: 500;
	transition: $trs;
	cursor: pointer;
	&:hover {
		background: $active-color;
		color: white;
	}
}
</style>
