<template>
  <div class="blocks" :class="{ 'sub-block': subBlock }">
    <div v-if="subBlock" class="settings-block">
      <div @click="$emit('deleteDataBlock')" class="delete-block">
        <img src="/static/icons/close.svg" alt="" />
      </div>
      <h2>Вложенный блок</h2>
      <custom-checkbox
        label="Множественный тип"
        @get="setData('kind', $event ? 'Array' : 'Single')"
      />
    </div>
    <input-container
      :value="blockStructure.label"
      :options="{
        type: 'Text',
        label: 'Название блока',
        required: true,
      }"
      @get="setLabel"
    />
    <template v-for="(b, k) in blockStructure.fields">
      <create-block-component
        v-if="b.type === 'SubBlock'"
        :key="k"
        :blockTypes="blockTypes"
        :blockStructure="b"
        :subBlock="true"
        @deleteDataBlock="deleteDataBlock(k)"
        @setData="setSubBlockData(k, $event)"
      />
      <create-form-component
        v-else
        :key="k"
        :formData="b"
        @setData="setSubBlockData(k, $event)"
        @deleteDataBlock="deleteDataBlock(k)"
      />
    </template>
    <custom-selector
      :key="blockStructure.fields.length"
      :options="{
        label: 'Добавить поле',
        inValue: false,
        data: blockTypes,
        size: 2,
      }"
      @get="addForm"
    />
  </div>
</template>

<script>
import CreateFormComponent from "./CreateFormComponent.vue";
import CustomSelector from "../Blocks/CustomSelector.vue";
import InputContainer from "../Blocks/InputContainer.vue";
import CustomCheckbox from "../Blocks/CustomCheckbox.vue";
import inputHandlerMixin from "@/mixins/inputHandlerMixin";
export default {
  name: "CreateBlockComponent",
  mixins: [inputHandlerMixin],
  components: {
    CustomSelector,
    CreateFormComponent,
    InputContainer,
    CustomCheckbox,
  },
  props: {
    subBlock: {
      default: false,
    },
    blockTypes: {
      default: () => [],
    },
    blockStructure: {
      default: () => ({
        name: "",
        label: "",
        fields: [],
      }),
    },
  },
  methods: {
    setLabel(label) {
      this.setData("label", label).then(() => {
        this.setData("name", this.labelToName(label));
      });
    },
    setData(key, data) {
      return new Promise((resolve) => {
        this.$emit("setData", { ...this.blockStructure, [key]: data });
        resolve();
      });
    },
    setSubBlockData(key, data) {
      console.log(key, data);
      const fields = [...this.blockStructure.fields];
      fields[key] = data;
      this.setData("fields", fields);
    },
    deleteDataBlock(k) {
      const fields = [...this.blockStructure.fields];
      fields.splice(k, 1);
      this.setData("fields", fields);
    },
    addForm(data) {
      data = data[0];
      const field = {
        type: data.type,
        kind: "Single",
        name: "",
        label: "",
        languages: [],
        params: {},
        validation: {
          required: false,
        },
        fields: [],
      };
      this.setData("fields", [...this.blockStructure.fields, field]);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/components/styles/config.scss";

.blocks {
  width: ptr(800px);
  display: grid;
  gap: ptr(40px);
}

.sub-block {
  padding-left: ptr(50px);
  position: relative;
}

h2 {
  margin: ptr(5px) 0;
}

.settings-block {
  display: grid;
  gap: ptr(20px);
  .delete-block {
    position: absolute;
    left: 0;
    top: ptr(5px);
  }
}
</style>
