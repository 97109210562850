import axios from "axios";
import { authService } from "./authService";

class HttpService {
	static getService() {
		let instance = axios.create({
			validateStatus: () => {
				return true;
			},
		});

		instance = this.setMiddlewares(instance);
		instance = this.addFunctions(instance);

		return instance;
	}

	static setMiddlewares(inst) {
		inst.interceptors.request.use(authService.reqMiddleware);
		inst.interceptors.response.use(authService.resMiddleware);

		return inst;
	}

	static addFunctions(inst) {
		inst.isOk = this.isOk;
		inst.formateUrl = this.formateUrl;

		return inst;
	}

	static isOk(res) {
		return res.status >= 200 && res.status <= 302;
	}

	static formateUrl(url) {
		return url;
	}
}
export const http = HttpService.getService();
