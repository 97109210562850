<template>
  <section>
    <div class="top-block">
      <h1>{{ data.title[0].value }}</h1>
      <lang-selector @get="(e) => (language = e)"></lang-selector>
    </div>
    <div class="blocks">
      <!-- :data="b" form -->
      <!-- :info="data.blocks[getCurrentScheme(b.id)]" block -->
      <ContentBlock
        ref="blocks"
        v-for="(f, k) in form"
        :key="f.id + k"
        :form="f"
        :block="data.blocks[getCurrentScheme(f.id)]"
        :language="language"
        :outside="true"
        @setData="setBlocks($event, k)"
      />
    </div>
    <button @click="saveAbout" class="add">Обновить данные</button>
  </section>
</template>

<script>
import { http } from "../components/AuthService/httpService";
import inputHandlerMixin from "@/mixins/inputHandlerMixin";
import LangSelector from "./components/LangSelector.vue";
import ContentBlock from "@/components/Blocks/ContentBlock.vue";
export default {
  components: { LangSelector, ContentBlock },
  mixins: [inputHandlerMixin],
  data() {
    return {
      language: "ru-RU",
      id: 0,
      form: [],
      data: {
        title: [
          {
            value: "",
          },
        ],
      },
    };
  },
  methods: {
    collectBlocks() {
      http.get("/api/blocks").then((response) => {
        if (response.status < 400) {
          for (let b of response.data.blocks) {
            if (this.data.blocks.findIndex((e) => e.id === b.id) > -1) {
              this.form.push(b);
            }
          }
        }
      });
    },
    getCurrentScheme(id) {
      return this.data.blocks.findIndex((e) => e.id === id);
    },
    setBlocks(data, k) {
      this.data.blocks.splice(k, 1, data);
    },
    getPageInfo(id) {
      http.get(`/api/pages/${id}`).then((response) => {
        this.data = response.data;
        this.collectBlocks();
      });
    },
    saveAbout() {
      this.loading = true;
      const recursiveImageId = (fields, data) => {
        const newData = { ...data };
        for (let f of fields) {
          if (
            (f.type === "Image" || f.type === "File") &&
            newData[f.name] !== null
          ) {
            newData[f.name] =
              f.kind === "Array"
                ? newData[f.name].map((i) => i.id)
                : newData[f.name].id;
          } else if (f.type === "SubBlock") {
            if (f.kind === "Array") {
              newData[f.name] = newData[f.name].map((e) =>
                recursiveImageId(f.fields, e)
              );
            } else {
              newData[f.name] = recursiveImageId(f.fields, newData[f.name]);
            }
          }
        }
        return newData;
      };
      const projectData = {
        ...this.data,
        blocks: [],
      };
      for (let f of this.form) {
        const blocks =
          this.data.blocks[this.data.blocks.findIndex((e) => e.id === f.id)];
        projectData.blocks.push({
          ...blocks,
          data: recursiveImageId(f.fields, blocks.data),
        });
      }
      if (!this.haveErrors()) {
        http.put(`/api/pages/${this.id}`, projectData).then(() => {
          this.loading = false;
          this.getPageInfo(this.id);
        });
      } else
        document.dispatchEvent(
          new CustomEvent("loadPosition", {
            detail: {
              message: "Вы не заполнили требуемые данные",
              type: "error",
            },
          })
        );
    },
    haveErrors(txt = null) {
      let ch = false;
      for (let b in this.$refs) {
        if (this.$refs[b].length !== undefined) {
          this.$refs[b].forEach((e) => {
            if (ch === false) {
              ch = e.checkReq(txt);
              console.log(ch, e);
            } else e.checkReq(txt);
          });
        } else {
          if (ch === false) {
            ch = this.$refs[b].checkReq(txt);
          } else {
            this.$refs[b].checkReq(txt);
          }
        }
      }
      return ch;
    },
    // addDataBlock(blocks) {
    // 	for (let b of blocks) {
    // 		const ids = this.allBlocks.map((e) => e.id);
    // 		this.data.blocks.push(this.allBlocks[ids.indexOf(b.id)]);
    // 		this.dataInfo.push(b);
    // 	}
    // },
  },
  created() {
    this.id = this.$route.params.id;
    this.getPageInfo(this.id);
  },
};
</script>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

section {
  display: flex;
  flex-direction: column;
  gap: ptr(100px);
}

.top-block {
  display: flex;
  align-items: center;
  margin: 0 0 ptr(20px);
  h1 {
    margin: 0;
    font-size: ptr(36px);
    font-weight: 600;
    color: $h-color;
  }
}

h2 {
  margin: 0 0 ptr(20px);
  font-size: ptr(36px);
  font-weight: 500;
  color: $h-color;
}

.stck {
  margin-top: ptr(60px);
  padding: ptr(20px) 0;
  position: sticky;
  top: 0;
  z-index: 10;
  background: $back-color;
}

.static-content {
  display: flex;
  flex-wrap: wrap;
  max-width: ptr(700px);
  gap: ptr(30px) ptr(20px);
}
.mass-container {
  display: flex;
  flex-wrap: wrap;
  gap: ptr(30px) ptr(20px);
}
.control-btns {
  display: flex;
  align-items: center;
}
.active {
  box-shadow: 0 ptr(4px) ptr(7px) rgba(127, 170, 211, 0.3);
}
.blocks {
  margin: 0;
  h2 {
    margin: 0;
  }
  display: grid;
  gap: ptr(100px);
}
</style>
