<template>
	<div class="load-container">
		<div
			class="load-line"
			:style="{
				width: linePos + '%',
				background: lineColor,
			}"
		></div>
		<div v-if="errorMessage" class="error-message">
			{{ errorMessage }}
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			linePos: 0,
			lineColor: "#00A3FF",
			errorMessage: "",
		};
	},
	mounted() {
		document.addEventListener("loadPosition", (e) => {
			switch (e.detail.type) {
				case "start":
					this.lineColor = "#00A3FF";
					this.linePos = 30;
					setTimeout(() => {
						if (this.linePos !== 0 && this.linePos !== 100)
							this.linePos = 80;
					}, 500);
					break;
				case "error":
					this.lineColor = "#bd2a2f";
					this.linePos = 100;
					console.log(e.detail.message);
					if (
						e.detail.Details === null ||
						e.detail.Details === undefined
					)
						this.errorMessage = e.detail.message;
					for (let d in e.detail.details) {
						this.errorMessage += `${d}: ${e.detail.details[d]}\n`;
					}
					setTimeout(() => {
						this.linePos = 0;
						this.errorMessage = "";
					}, 5000);
					break;
				case "serverError":
					this.linePos = 100;
					this.lineColor = "#c16668";
					this.errorMessage = "Ошибка сервера";
					setTimeout(() => {
						this.linePos = 0;
						this.errorMessage = "";
					}, 5000);
					break;
				case "succes":
					this.lineColor = "#36f1ac";
					this.linePos = 100;
					setTimeout(() => {
						if (this.linePos === 100) this.lineColor = "#ffffff";
					}, 200);
					setTimeout(() => {
						if (this.linePos === 100) this.linePos = 0;
					}, 400);
					break;
				default:
					break;
			}
		});
	},
};
</script>

<style lang="scss" scoped>
@import "./styles/config.scss";

.load-container {
	width: 100vw;
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: flex-start;
	z-index: 100;
}
.load-line {
	height: ptr(4px);
	width: 0%;
	background: $active-color;
	transition: 0.3s ease-out;
}
.error-message {
	background: white;
	border-radius: ptr(5px);
	border: 2px solid #bd2a2f;
	padding: ptr(20px);
	position: absolute;
	right: ptr(40px);
	top: ptr(20px);
}
</style>
