import Vue from "vue";
import VueRouter from "vue-router";
import EnterPage from "@/views/EnterPage";
import ContentPage from "@/views/ContentPage";
import CreateBlocksPage from "@/views/CreateBlocksPage";
import CreatePage from "@/views/CreatePage";
import ControlPage from "@/views/ControlPage";
import Cases from "@/views/Cases";
import Case from "@/views/Case";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		redirect: "/cases",
	},
	{
		path: "/auth",
		name: "Authitication",
		component: EnterPage,
	},
	{
		path: "/page/:id",
		name: "ContentPage",
		component: ContentPage,
	},
	{
		path: "/cases",
		name: "Cases",
		component: Cases,
	},
	{
		path: "/case/:id?",
		name: "Case",
		component: Case,
	},
	{
		path: "/control-panel",
		name: "CreateBlocksPage",
		component: ControlPage,
	},
	{
		path: "/blocks-control-page/:structure?",
		name: "CreateBlocksPage",
		component: CreateBlocksPage,
	},
	{
		path: "/pages-control-page/:id?",
		name: "CreatePage",
		component: CreatePage,
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
