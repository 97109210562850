<template>
	<div class="order-conteiner">
		<div @click="setOrder(1)" class="arrow-container">
			<img src="/static/icons/arrow.svg" alt="" />
		</div>
		<p>{{ order + 1 }}</p>
		<div @click="setOrder(-1)" class="arrow-container">
			<img src="/static/icons/arrow.svg" alt="" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		order: {
			default: 0,
		},
	},
	methods: {
		setOrder(n) {
			this.$emit("changeOrder", this.order + n);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/components/styles/config.scss";

.order-conteiner {
	display: flex;
	border-radius: ptr(5px);
	font-size: ptr(18px);
	border: 1px solid $border-color;
	color: $h-color;
	width: fit-content;
	margin-left: ptr(30px);
}
.arrow-container {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: ptr(10px) ptr(10px);
	cursor: pointer;
	transition: $trs;
	img {
		width: ptr(12px);
		transition: $trs;
	}
	&:hover {
		background: $active-color;
		img {
			filter: brightness(0) invert(1);
		}
	}
}
.arrow-container:nth-child(3) {
	img {
		transform: rotate(180deg);
	}
}
p {
	padding: 0 ptr(10px);
	margin: 0;
	display: flex;
	align-items: center;
	border-left: 1px solid $border-color;
	border-right: 1px solid $border-color;
}
</style>
