<template>
	<article>
		<div class="setting-block">
			<h3>
				{{ formData.type }}
			</h3>
			<div class="delete-block" @click="$emit('deleteDataBlock')">
				<img src="/static/icons/close.svg" alt="" />
			</div>
		</div>
		<input-container
			:value="formData.label"
			:options="{ label: 'Название поля' }"
			@get="setLabel"
		/>
		<input-container
			:value="formData.validation.minCount"
			:options="{ label: 'Минимальное количество' }"
			@get="setOptions('validation', 'minCount', parseInt($event))"
		/>
		<input-container
			:value="formData.validation.maxCount"
			:options="{ label: 'Максимальное количество' }"
			@get="setOptions('validation', 'maxCount', parseInt($event))"
		/>
		<input-container
			:value="formData.validation.exactCount"
			:options="{ label: 'Конкретное колличество' }"
			@get="setOptions('validation', 'exactCount', parseInt($event))"
		/>
		<custom-checkbox
			label="Множественный тип"
			:value="formData.kind === 'Array'"
			@get="setData('kind', $event ? 'Array' : 'Single')"
		/>
		<custom-checkbox
			v-if="formData.type === 'Text' || formData.type === 'Textarea'"
			label="Переводимое поле"
			:value="formData.languages.length"
			@get="setData('languages', $event ? ['ru-RU'] : [])"
		/>
		<custom-checkbox
			label="Обязательно для заполнения"
			:startByTrue="true"
			:value="formData.validation.required"
			@get="setOptions('validation', 'required', $event)"
		/>
	</article>
</template>

<script>
import CustomCheckbox from "../Blocks/CustomCheckbox.vue";
import InputContainer from "../Blocks/InputContainer.vue";
import inputHandlerMixin from "@/mixins/inputHandlerMixin";
export default {
	mixins: [inputHandlerMixin],
	components: { InputContainer, CustomCheckbox },
	props: {
		formData: {
			default: () => ({
				id: 0,
				type: "Text",
				kind: "Single",
				name: "",
				label: "",
				languages: [],
				params: {},
				validation: {
					required: false,
				},
				fields: [],
			}),
		},
	},
	methods: {
		setLabel(label) {
			this.setData("label", label).then(() => {
				this.setData("name", this.labelToName(label));
			});
		},
		setOptions(name, key, data) {
			this.setData(name, { ...this.formData[name], [key]: data });
		},
		setData(key, data) {
			return new Promise((resolve) => {
				this.$emit("setData", { ...this.formData, [key]: data });
				resolve();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/components/styles/config.scss";

article {
	display: grid;
	grid-template-columns: repeat(2, max-content);
	gap: ptr(20px);
}

.delete-block {
	margin-left: ptr(20px);
}

.setting-block {
	display: flex;
	grid-column: span 2;
	align-items: center;
	h3 {
		margin: 0;
		font-size: ptr(20px);
	}
}
</style>
