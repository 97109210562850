<template>
	<div class="container">
		<template v-if="isArray">
			<template v-for="(s, i) in block.data[form.name]">
				<ContentBlock
					:ref="form.name"
					:key="form.type + i"
					:form="form"
					:language="language"
					:block="{ data: s, sortOrder: i }"
					:subBlock="true"
					:last="block.data[form.name].length - 1 === i"
					@sort="sortBlocks"
					@setData="setBlocks({ data: $event, key: i })"
					@addBlock="$emit('addBlock')"
					@deleteBlock="$emit('deleteBlock', i)"
				/>
			</template>
		</template>
		<!-- @setData="$emit('setBlocks', { data: $event, key: i })" -->
		<template v-else>
			<ContentBlock
				:ref="form.name"
				:form="form"
				:language="language"
				:block="{ data: block.data[form.name] }"
				:subBlock="true"
				@setData="setBlocks({ data: $event })"
			/>
		</template>
	</div>
</template>

<script>
export default {
	name: "multiple-block",
	components: { ContentBlock: () => import("./ContentBlock.vue") },
	props: {
		block: {
			default: () => ({}),
		},
		form: {
			default: () => ({}),
		},
		language: {
			default: "ru-RU",
		},
	},
	methods: {
		checkReq(errComponent) {
			let chk = false;
			for (let k in this.$refs) {
				chk = this.isArray
					? this.$refs[k].forEach((e) => e.checkReq(errComponent))
					: this.$refs[k].checkReq(errComponent);
			}
			return chk;
		},
		setBlocks(event) {
			let filteredArray;
			if (this.isArray) {
				filteredArray = [...this.block.data[this.form.name]];
				filteredArray.splice(event.key, 1, event.data);
			} else {
				filteredArray = event.data;
			}
			this.$emit("setBlocks", filteredArray);
		},
		sortBlocks(lastPos, nextPos) {
			const copyBlock = [...this.block.data[this.form.name]];
			copyBlock.forEach((e, k) => (e.sortOrder = k));
			copyBlock[nextPos].sortOrder = lastPos;
			copyBlock[lastPos].sortOrder = nextPos;
			this.$emit(
				"setBlocks",
				copyBlock.sort((a, b) => {
					return a.sortOrder - b.sortOrder;
				})
			);
		},
	},

	computed: {
		isArray() {
			return this.form.kind === "Array";
		},
	},
};
</script>
<style lang="scss" scoped>
@import "@/components/styles/config.scss";
.container {
	display: grid;
	gap: ptr(50px);
	width: ptr(700px);
}
</style>
