<template>
  <section>
    <div class="top-block">
      <h1>Создать страницу</h1>
    </div>
    <input-container
      :options="{ label: 'Название страницы' }"
      :value="whichVal('title')"
      @get="onChange('title', $event)"
    />
    <input-container
      :options="{ label: 'Ссылка на страницу' }"
      :value="pageData.url"
      @get="pageData.url = $event"
    />
    <div class="blocks">
      <h2>Содержимое страницы:</h2>
      <div v-for="(b, k) in pageData.blocks" :key="k" class="selected-block">
        <h3>
          {{ b.scheme.label }}
        </h3>
        <img src="/static/icons/×.svg" alt="" @click="deleteBlock(b.id)" />
        <p class="length">Всего элементов: {{ b.scheme.fields.length }}</p>
        <p class="elements">
          {{ b.scheme.fields.map((e) => e.label).join(", ") }}
        </p>
      </div>
      <block-selector :data="allBlocks" :withLabel="false" @add="addBlock" />
    </div>
    <div class="btns">
      <button @click="savePageData" class="add">
        {{ id !== 0 ? "Обновить данные" : "Создвть страницу" }}
      </button>
      <button v-if="id !== 0" @click="deletePage" class="delete">
        Удалить
      </button>
    </div>
  </section>
</template>

<script>
import { http } from "../components/AuthService/httpService";
import InputContainer from "@/components/Blocks/InputContainer.vue";
import BlockSelector from "@/components/Blocks/BlockSelector.vue";
import inputHandlerMixin from "@/mixins/inputHandlerMixin";
export default {
  components: { BlockSelector, InputContainer },
  mixins: [inputHandlerMixin],
  data() {
    return {
      pageData: {
        title: [
          {
            lang: "ru-RU",
            value: "",
          },
        ],
        url: "",
        blocks: [],
      },
      objName: "pageData",
      language: "ru-RU",
      allBlocks: [],
      id: 0,
    };
  },
  methods: {
    getBlocks() {
      http.get("/api/blocks").then((response) => {
        if (response.status < 400) {
          this.allBlocks = response.data.blocks;
        }
      });
    },
    addBlock(b) {
      this.pageData.blocks.push({
        scheme: { ...b },
        block: {
          sortOrder: this.pageData.blocks.length,
          data: this.add(b.fields),
          id: b.id,
        },
      });
    },
    deletePage() {
      http.delete("/api/pages/" + this.id).then((response) => {
        if (response.status < 400) {
          this.$router.go(-1);
        }
      });
    },
    deleteBlock(id) {
      id = this.pageData.blocks.findIndex((e) => e.id === id);
      this.pageData.blocks.splice(id, 1);
    },
    onChange(name, value) {
      const filteredArray = this[this.objName][name].filter(
        (e) => e.lang !== this.language
      );
      this[this.objName][name] = [
        ...filteredArray,
        {
          lang: this.language,
          value,
        },
      ];
    },
    getPage(id) {
      http.get("/api/pages/" + id).then(({ data }) => {
        this.pageData = {
          ...data,
          blocks: data.blocks.map((b) => ({
            block: { ...b },
            scheme:
              this.allBlocks[this.allBlocks.findIndex((e) => e.id === b.id)],
          })),
        };
      });
    },
    savePageData() {
      if (this.id === 0) {
        http
          .post("/api/pages", {
            ...this.pageData,
            blocks: this.pageData.blocks.map((e) => e.block),
          })
          .then((response) => {
            if (response.status < 400) {
              this.$router.go(-1);
            }
          });
      } else {
        http.put("/api/pages/" + this.id, {
          ...this.pageData,
          blocks: this.pageData.blocks.map((e) => e.block),
        });
      }
    },
  },
  mounted() {
    this.getBlocks();
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.getPage(this.id);
    }
  },
};
</script>

<style lang="scss">
@import "../components/styles/config.scss";

.delete-block {
  background: $red;
  border-radius: ptr(5px);
  width: ptr(30px);
  height: ptr(30px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: ptr(10px);
  }
}
</style>

<style lang="scss" scoped>
@import "../components/styles/config.scss";

section {
  display: grid;
  gap: ptr(20px);
}

.top-block {
  display: flex;
  align-items: center;
  margin: 0 0 ptr(20px);
  h1 {
    margin: 0;
    font-size: ptr(36px);
    font-weight: 600;
    color: $h-color;
  }
}

h2 {
  margin: ptr(20px) 0 ptr(10px);
  font-size: ptr(26px);
  font-weight: 600;
  color: $h-color;
}

.stck {
  margin-top: ptr(60px);
  padding: ptr(20px) 0;
  position: sticky;
  top: 0;
  z-index: 10;
  background: $back-color;
}

.static-content {
  display: flex;
  flex-wrap: wrap;
  max-width: ptr(700px);
  gap: ptr(30px) ptr(20px);
}
.mass-container {
  display: flex;
  flex-wrap: wrap;
  gap: ptr(30px) ptr(20px);
}
.control-btns {
  display: flex;
  align-items: center;
}
.active {
  box-shadow: 0 ptr(4px) ptr(7px) rgba(127, 170, 211, 0.3);
}
.blocks {
  margin: 0;
  display: grid;
  h2 {
    grid-column: span 4;
  }
  gap: ptr(10px);
  grid-template-columns: repeat(4, 1fr);
}

.selected-block {
  display: grid;
  grid-auto-rows: max-content;
  border-radius: $brd;
  background: white;
  padding: ptr(15px) ptr(15px);
  min-height: ptr(80px);
  border: 1px solid $border-color;
  gap: ptr(2px);
  position: relative;
  p {
    margin: 0;
    color: $text-color;
  }
  h3 {
    margin: 0;
    font-weight: 600;
    font-size: ptr(20px);
    padding-right: ptr(20px);
    color: $h-color;
  }
  .length {
    font-size: ptr(14px);
  }

  .elements {
    margin-top: ptr(10px);
  }
  img {
    cursor: pointer;
    position: absolute;
    width: ptr(15px);
    height: ptr(15px);
    right: ptr(15px);
    top: ptr(15px);
    filter: brightness(0.1);
  }
}

.delete-block {
  width: unset;
  height: unset;
  margin-top: ptr(40px);
  color: white;
  font-weight: 500;
  padding: ptr(10px);
}
</style>
