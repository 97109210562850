var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{class:{ 'sub-block': _vm.subBlock, outside: _vm.outside }},[_c('h2',[_vm._v(" "+_vm._s(_vm.form.label)+" "),((_vm.form.kind === 'Array' || !_vm.subBlock) && !_vm.outside)?_c('order-block',{attrs:{"order":_vm.block.sortOrder},on:{"changeOrder":_vm.sortByOrder}}):_vm._e()],1),_c('div',{staticClass:"mass-container"},[_vm._l((_vm.form.fields),function(d,k){return [(d.type === 'Checkbox')?_c('custom-checkbox',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"label":d.label,"value":_vm.block.data[d.name]},on:{"get":function($event){return _vm.emitConstructObject(d.name, $event)}}}):(d.type === 'Selector')?_c('custom-selector',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"label":d.label,"data":d.params.values,"kind":d.kind,"language":_vm.language,"selectedValue":_vm.block.data[d.name]},on:{"get":function($event){return _vm.emitConstructObject(d.name, $event)}}}):(d.type === 'Image' || d.type === 'File')?_c('photo-loader',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"options":{
          label: d.label,
          minContent: '',
          validation: d.validation,
          onlyOne: d.kind !== 'Array',
          type: d.type,
        },"images":_vm.block.data[d.name]},on:{"photoId":function($event){return _vm.setImgToObject(d.name, $event)}}}):(d.type === 'SubBlock')?_c('multiplie-block',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"form":d,"language":_vm.language,"block":_vm.block},on:{"setBlocks":function($event){return _vm.emitConstructObject(d.name, $event)},"addBlock":function($event){return _vm.addBlock(d, d.name)},"deleteBlock":function($event){return _vm.deleteBlock($event, d.name)}}}):(d.kind === 'Array' && d.type === 'Text')?_c('tag-container',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"value":_vm.block.data[d.name],"language":_vm.language,"options":{
          label: d.label,
          validation: d.validation,
          localisation: d.languages.length[0],
        }},on:{"get":function($event){return _vm.emitConstructObject(d.name, $event)}}}):_c('input-container',{key:d.type + k,ref:d.name,refInFor:true,attrs:{"value":d.languages.length > 0
            ? _vm.whichVal('data', null, d.name)
            : _vm.block.data[d.name],"options":{
          type: d.type,
          label: d.label,
          validation: d.validation,
          kind: d.kind,
        }},on:{"get":function($event){return _vm.onChange(d.name, $event, d.languages.length > 0)}}})]})],2),(_vm.checkButtons().all)?_c('div',{staticClass:"control-btns"},[(_vm.checkButtons().addBtn)?_c('button',{staticClass:"add",on:{"click":function($event){return _vm.$emit('addBlock')}}},[_vm._v(" Добавить ")]):_vm._e(),(_vm.checkButtons().deleteBtn)?_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.$emit('deleteBlock')}}},[_vm._v(" Удалить ")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }