<template>
	<section>
		<div class="top-block">
			<h1>Панель управления</h1>
			<div class="buttons-container">
				<router-link to="/blocks-control-page/" class="create-block">
					Создать блок
				</router-link>
				<router-link to="/pages-control-page" class="create-page">
					Создать страницу
				</router-link>
			</div>
		</div>
		<article>
			<h2>Страницы</h2>
			<div class="pages-container">
				<router-link
					v-for="(p, k) in allPages"
					:key="k"
					:to="`/pages-control-page/${p.id}`"
					class="page"
				>
					{{ p.title }}
				</router-link>
			</div>
		</article>
		<article>
			<h2>Блоки</h2>
			<div class="blocks-container">
				<div v-for="(b, index) in allBlocks" :key="index" class="block">
					<div class="name">
						<p>
							{{ b.label }}
						</p>
						<div tabindex="0" class="options">
							<div class="settings-component">
								<router-link
									:to="{
										path: 'blocks-control-page',
										query: { structure: b },
									}"
									class="opt"
								>
									Редактировать
								</router-link>
								<div @click="deleteBlock(b.id)" class="opt">
									Удалить
								</div>
							</div>
							<img src="/static/icons/kebab-menu.svg" alt="" />
						</div>
					</div>
					<div class="info">
						<div
							v-for="(f, k) in b.fields"
							:key="k"
							class="block-info"
						>
							<div class="name-block">
								<p>
									Название
								</p>
								<span>
									{{ f.label }}
								</span>
							</div>
							<div>
								<p>
									Тип
								</p>
								<span>
									{{ f.type }}
								</span>
							</div>
							<div>
								<p>
									Количество
								</p>
								<span>
									{{ f.kind }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</article>
	</section>
</template>

<script>
import { http } from "../components/AuthService/httpService";
export default {
	data() {
		return {
			allPages: [],
			allBlocks: [],
		};
	},
	methods: {
		getPages() {
			http.get("/api/pages").then((response) => {
				this.allPages = response.data.pages;
			});
		},
		getBlocks() {
			http.get("/api/blocks").then((response) => {
				this.allBlocks = response.data.blocks;
			});
		},
		deleteBlock(id) {
			http.delete("/api/blocks/" + id).then((response) => {
				if (response.status < 400) {
					this.getBlocks();
				}
			});
		},
	},
	mounted() {
		this.getPages();
		this.getBlocks();
	},
};
</script>

<style lang="scss" scoped>
@import "@/components/styles/config.scss";

.top-block {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 0 ptr(50px);
	h1 {
		margin: 0 0 0 ptr(20px);
		font-size: ptr(36px);
		font-weight: 600;
		color: $h-color;
	}
}

h2 {
	margin: 0 0 ptr(10px) ptr(20px);
	font-size: ptr(30px);
	font-weight: 500;
	color: $h-color;
}

.buttons-container {
	display: flex;
}

.create-block {
	background: none;
	color: $active-color;
	font-weight: 600;
	height: ptr(45px);
	display: flex;
	align-items: center;
	padding: 0 ptr(20px);
}

.create-page {
	width: ptr(180px);
	height: ptr(45px);
	margin-left: ptr(30px);
	border-radius: $brd;
	background: #00a3ff;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

.pages-container {
	margin-bottom: ptr(80px);
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	gap: ptr(50px);
}

.page {
	display: flex;
	align-items: center;
	font-weight: 500;
	color: $h-color;
	font-size: ptr(18px);
	padding: 0 ptr(20px);
	width: ptr(200px);
	height: ptr(60px);
	border-radius: ptr(8px);
	box-shadow: 0 ptr(13px) ptr(23px) rgba(86, 122, 231, 0.175039);
	cursor: pointer;
	transition: $trs;
	&:hover {
		background: $active-color;
		color: white;
	}
}

.blocks-container {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: ptr(50px);
}

.block {
	padding: ptr(20px) ptr(20px) ptr(30px);
	position: relative;
	border-radius: ptr(10px);
	box-shadow: 0 ptr(13px) ptr(23px) rgba(86, 122, 231, 0.175039);
}

.name {
	font-size: ptr(20px);
	font-weight: 600;
	margin-bottom: ptr(20px);
	p {
		margin: 0;
	}
}

.info {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: ptr(10px);
}

.block-info {
	background: $back-color;
	border: 1px solid #d2def5;
	padding: ptr(15px);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: ptr(10px);
	border-radius: ptr(10px);
	p {
		margin: 0;
		font-weight: 500;
		font-size: ptr(14px);
		color: $text-color;
		margin-bottom: ptr(3px);
	}
	span {
		display: block;
		font-weight: 500;
		font-size: ptr(16px);
		min-height: ptr(19px);
	}
}
.name-block {
	grid-column: span 2;
}
.options {
	display: flex;
	align-items: center;
	justify-content: center;
	right: ptr(20px);
	height: ptr(40px);
	top: ptr(10px);
	position: absolute;
	cursor: pointer;
	img {
		width: ptr(24px);
	}
	&:focus-within {
		.settings-component {
			opacity: 1;
			pointer-events: all;
		}
	}
}

.settings-component {
	position: absolute;
	width: ptr(198px);
	border-radius: $brd;
	border: 1px solid $border-color;
	color: $text-color;
	z-index: 200;
	right: 120%;
	top: ptr(-6px);
	opacity: 0;
	pointer-events: none;
	transition: $trs;
	&::after {
		position: absolute;
		content: "";
		border-radius: ptr(3px);
		width: ptr(12px);
		height: ptr(12px);
		background: rgb(255, 255, 255);
		border: 1px solid $border-color;
		right: ptr(-5px);
		top: ptr(18px);
		transform: rotate(45deg);
	}
}
.opt {
	height: ptr(46px);
	display: flex;
	align-items: center;
	padding: 0 ptr(20px);
	font-size: ptr(16px);
	background: white;
	position: relative;
	z-index: 2;
	font-weight: 500;
	transition: $trs;
	cursor: pointer;
	&:hover {
		background: $active-color;
		color: white;
	}
}
</style>
